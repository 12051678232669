import { render, staticRenderFns } from "./OdometerReminder.vue?vue&type=template&id=2db2b621&scoped=true&lang=pug"
import script from "./OdometerReminder.vue?vue&type=script&lang=js"
export * from "./OdometerReminder.vue?vue&type=script&lang=js"
import style0 from "./OdometerReminder.vue?vue&type=style&index=0&id=2db2b621&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db2b621",
  null
  
)

export default component.exports
export const searchMixin = {
  methods: {
    searchObject: function(theObject, regEx, isFound) {
      if (isFound == false && theObject != null) {
        // find the keys
        let tempArray = Object.keys(theObject);
        for (var i = 0; i < tempArray.length; i++) {
          if (theObject[tempArray[i]] != null) {
            if (theObject[tempArray[i]].toString().match(regEx)) {
              isFound = true;
              break;
            }
          }
        }
      }
      return isFound;
    },

    searchList: function(theList, regEx, isFound) {
      if (isFound == false && theList != null && theList.length > 0) {
        for (var i = 0; i < theList.length; i++) {
          isFound = this.searchObject(theList[i], regEx, isFound);
        }
      }
      return isFound;
    }
  }
};

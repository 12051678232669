<template lang="pug">
  v-data-table.elevation-1(
    :headers='headers',
    :items='$store.state.routing.routing_data'
    item-key="id"
    :single-select="false"
    show-select
    @click:row="onClickRow"
  )

    template(v-slot:progress)
      v-progress-linear(slot='progress', height='2', :indeterminate='loading')

    template(v-slot:item.vehicle="{item}")
      vehicle-column(v-if="item.vehicle" :vehicle="item.vehicle")

    template(v-slot:item.operator="{item}")
      contact-column(v-if="item.vehicle && item.vehicle.operator" :contact="item.vehicle.operator")

    template(v-slot:item.name="{item}")
      div()
        .flex()
          span()
            template(v-if="item.locked")
              v-icon() lock
            template(v-else)
              v-icon() lock_open
          span.ml-2.font-weight-bold(v-html="item.name")
      .caption.grey--text(v-html="item.description")

    template(v-slot:item.start_date_time="{item}")
      div(v-html="getStartDate(item.start_date_time)")
      .caption.grey--text.text--darken-2(v-if="item.workflow")
        v-icon.mr-2(small) assignment
        | {{item.workflow.name}}

    template(v-slot:item.tsp_details="{item}")
      div(v-if="item.tsp_details")
        template(v-if="item.locked")
          div() {{item.tsp_details.trips[0].legs.length}} Waypoints
        template(v-else)
          div() {{item.tsp_details.routes[0].legs.length}} Waypoints

    template(v-slot:item.start_place="{item}")
      template(v-if="item.locked")
        div() {{(item.tsp_details.trips[0].distance/1000.0).toFixed(2)}} Km
      template(v-else)
        div() {{(item.tsp_details.routes[0].distance/1000.0).toFixed(2)}} Km


    template(v-slot:item.order_details="{item}")
      div(v-if="item.order_details") {{item.order_details[0].name}} - {{item.order_details[item.order_details.length-1].name}}
      .caption.grey--text()
        template(v-if="item.locked")
          span() {{(item.tsp_details.trips[0].distance/1000.0).toFixed(2)}} Km
          span.ml-6() {{getTotalTime(item.tsp_details.trips[0].duration)}}
        template(v-else)
          span() {{(item.tsp_details.routes[0].distance/1000.0).toFixed(2)}} Km
          span.ml-6() {{getTotalTime(item.tsp_details.routes[0].duration)}}

    template(v-slot:item.end_place="{item}")
      div(v-if="item.end_place") {{item.end_place.name}}

    template( v-slot:item.action="{ item }")
      v-row.mr-7(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')



</template>

<script>
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import ContactColumn from "@/modules/global/components/table/ContactColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import moment from "moment";
export default {
  components: {
    MoreDropDownMenu,
    ImageThumbnail,
    VehicleColumn,
    ContactColumn
  },
  data: () => ({
    loading: false,
    headers: [
      { text: "Vehicle", value: "vehicle" },
      { text: "Name", value: "name" },
      { text: "Start Date", value: "start_date_time" },
      { text: "Waypoints", value: "tsp_details" },
      { text: "Start Location - End Location", value: "order_details" },
      { text: "Operator", value: "operator" },
      { text: "Action", value: "action", align: "right" }
    ],

    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Duplicate", icon: "content_copy", action: "duplicate" },
      { title: "Delete", icon: "delete", action: "delete" },
      { title: "OpenLink", icon: "place", action: "open_link" },
      { title: "Download PDF", icon: "place", action: "downloadPDF" },
      { title: "Download PDF No Map", icon: "place", action: "downloadPDFNoMap" }
    ]
  }),

  mounted() {
    this.$store.commit("routing/setSelectedPlaces",[]);
    if (this.$store.state.routing.currentIndex == -1) {
      // load Data
      this.$store.dispatch("routing/loadAllRoutings").then(() => {});
    }
  },


  methods: {

    onClickRow:function(item){
      console.log(item.id);
      this.$store.commit("routing/setCurrentIndexByID",item.id);

      this.$store.commit("showRightDrawer",{title:this.$store.getters["routing/getCurrentRouting"].name,width:800,component:"RightRouteMap"})
    },

    getTotalTime: function(theDuration){
      let theSeconds = moment
          .utc(moment.duration(theDuration, "seconds").asMilliseconds())
          .format("HH:mm:ss");

      return theSeconds
    },

    getStartDate:function(value){
      return moment(value).format("ddd, Do MMM YYYY, h:mm a")
    },
    onClick: function(value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("routing/setCurrentIndexByID", value.theObject.id);
          this.$router.push({
            name: "routeDetailsForm",
            params: { placeID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          confirm("Are you sure you want to delete this item?") &&
            this.$store.dispatch("routing/deleteRouting", value.theObject.id);
          break;
        case "duplicate":
          this.$store.commit("routing/setCurrentIndexByID",value.theObject.id)
          this.$store.dispatch("routing/duplicateRouting", value.theObject.id);
          break;
        case "open_link":
          window.open(`/api/renderhtml?type=osrmRoute&osrm_id=${value.theObject.id}&account_id=${this.$store.state.profile.userInfo.account.id}&showMap=`);
          break;
        case "downloadPDF":
          window.open(`/api/renderhtml?type=osrmRoutePDF&osrm_id=${value.theObject.id}&account_id=${this.$store.state.profile.userInfo.account.id}&showMap=`);
          break;
        case "downloadPDFNoMap":
          window.open(`/api/renderhtml?type=osrmRoutePDF&osrm_id=${value.theObject.id}&account_id=${this.$store.state.profile.userInfo.account.id}`);
          break;
      }
    }
  }
};
</script>

<style scoped>
</style>
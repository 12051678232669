<template lang="pug">
  div(v-if="$store.state.settings.customFieldSettings.currentIndex > -1")
    .theForm.mb-6
      v-btn.mx-2(small color="primary" outlined @click="cancel") Cancel
      v-btn.mx-2(small color="primary" @click="save") Save
    v-card
      v-container
        v-container(grid-list-xl)
          form-divider.mb-4 CUSTOM FIELD
          v-row()
            v-col(cols="12" xs="12" md="6")
              v-text-field(v-model="proxyLabel"  hint="Enter name of the Label" :persistent-hint="true"  label="Label Name*"  required)
            v-col(cols="12" xs="12" md="6")
              v-text-field(v-model="label_id"  disabled  label="Label ID"  required)
          v-row()
            v-col(cols="12" xs="12" md="6")
              v-select(v-model='type', :items='typeItems',item-value="id" item-text="label", label='Type',)
            v-col(cols="6" xs="6" md="3")
                v-text-field(v-model="proxyOrderNo"  hint="Order No" :persistent-hint="true"  label="Order No*"  required @blur="onBlurOrderNo")
            v-col(cols="6" xs="6" md="3")
              template(v-if="type == 'integer' || type == 'decimal'")
                v-text-field(v-model.number="proxyDefault"  hint="Default Value (Optional)" :persistent-hint="true"  label="Default Value (Optional)"  required @blur="onBlurOrderNo")
              template(v-else)
                v-text-field(v-model="proxyDefault"  hint="Default Value (Optional)" :persistent-hint="true"  label="Default Value (Optional)"  required @blur="onBlurOrderNo")
          form-divider.mb-4 REFERENCE DETAILS
          v-row()
            v-col(cols="12" xs="12" md="6")
              v-select(v-model='reference', :items='$store.state.settings.customFieldSettings.referenceItems', item-value="id" item-text="label" label='Reference',)
            v-col(cols="12" xs="12" md="6")
              template(v-if="reference === 'part'")
                auto-combo-box(v-model='reference_id' label="Select Part"  module="inventory" submodule="item" actionPrefix="Item" field="name")

</template>
<script>
import {createHelpers} from "vuex-map-fields";
import FormDivider from "@/modules/global/components/forms/FormDivider";
import AutoComboBox from "@/modules/global/components/forms/AutoComboBox";

const {mapFields} = createHelpers({
  getterType: "settings/customFieldSettings/getCustomField",
  mutationType: "settings/customFieldSettings/updateCustomField",
});
export default {
  components: {
    FormDivider,
    AutoComboBox
  },
  data: () => ({

    typeItems: [
      {label: "Integer (Whole Numbers)", id: "integer"},
      {label: "Decimal (Fractions)", id: "decimal"},
      {label: "Text", id: "text"},
    ]
  }),

  computed: {

    ...mapFields([
      "label_id",
      "label",
      "type",
      "order_no",
      "reference",
      "reference_id",
      "details"
    ]),
    proxyLabel: {
      get: function () {
        return this.label;
      },
      set: function (value) {
        console.log(value)
        this.label_id = this._.snakeCase(value)
        this.label = value
      }
    },

    proxyDefault: {
      get: function (){
        if (this.details == null || this.details.default == null){
          return null
        }else{
          return this.details.default
        }
      },
      set: function (value) {
        let theObj = {}

        theObj["default"] = value;
        if (this.details == null){
          this.$store.commit("settings/customFieldSettings/updateCustomField", {path: `details`, value: theObj})
        }else{
          this.$store.commit("settings/customFieldSettings/updateCustomField", {path: `details.default`, value: value})
        }

      }

    },

    proxyOrderNo: {
      get: function () {
        return this.order_no;
      },
      set: function (value) {
        if (value === "") {
          this.order_no = null;
          return;
        }
        if (!isNaN(value)) {
          this.order_no = parseInt(value);
          console.log(this.order_no)
        }

      }
    },


  },

  methods: {
    onBlurOrderNo: function () {
      this.proxyOrderNo = this.order_no;
    },
    cancel: function () {
      this.$store.dispatch("settings/customFieldSettings/loadAllCustomFields")
      this.$router.push({name: "customFieldsSettings"}).catch(()=>{});
    },

    save: function () {
      this.$store.dispatch("settings/customFieldSettings/saveCustomField").then(() => {
        this.$store.dispatch("settings/customFieldSettings/loadAllCustomFields")
        this.$router.push({name: "customFieldsSettings"}).catch(()=>{});
      })

    }
  },

  mounted() {
    if (this.$store.state.settings.customFieldSettings.currentIndex == -1) {
      this.$store.dispatch("settings/customFieldSettings/fetchCustomField", this.$route.params.customFieldID)
    }

  }
};
</script>
<style lang="stylus" scoped></style>

<template lang="pug">
  div(v-if="$store.state.merchant.currentIndex > -1")
    v-data-table.elevation-1(
      :headers="headers",
      :search="search",
      :items="getData",
      :custom-filter="customerModuleFilter"
      item-key="id",
      :single-select="false"
      :items-per-page="footerProps.pagnation.itemsPerPage",
      :footer-props="footerProps"
      show-select
    )
      v-progress-linear(slot='progress', height='2', :indeterminate='loading')

      template(v-slot:item.name="{item}")
        .showPointer(@click="onClick({action:'edit',theObject:{id:item.id}})")
          .body-1 {{ item.name }}
          .d-flex(v-if="item.first_name != null")
            v-icon(size="16") person
            .caption.grey--text.text--darken-1 {{item.first_name}} {{item.last_name}}

      template(v-slot:item.group="{item}")
        div(v-if="item.group_id != null") {{$store.getters["settings/groupSettings/getGroupByID"](item.group_id).name}}



      template(v-slot:item.phone="{item}")
        .caption()
          div() {{item.phone}}
          div() {{item.website}}
          div()
            a(:href="'mailto:'+item.email") {{ item.email }}

      template(v-slot:item.contacts="{item}")
        .caption(v-for="(contact,index) in item.contacts" :key="index")
          | - {{contact.first_name}} {{contact.last_name}}


      template(v-slot:item.for_fuel="{item}")
        .d-flex()
          v-icon.mx-1(v-if="item.for_fuel") local_gas_station
          v-icon.mx-1(v-if="item.for_parts") category
          v-icon.mx-1(v-if="item.for_service") build

      template(v-slot:item.places_id="{item}")
        div(v-if="item.places_id != null && placesLoaded")
          div(v-for="(id,index) in item.places_id" :key="index")
            .caption() {{$store.getters["place/getPlaceByID"](id).name }}

      template(v-slot:item.places_id="{item}")
        div(v-if="item.places_id != null && placesLoaded")
          div(v-for="(id,index) in item.places_id" :key="index")
            .caption() {{$store.getters["place/getPlaceByID"](id).name }}

      template(v-slot:item.street_address="{item}")
        div()
          div() {{item.street_address}}
          a()
            .caption(v-if="item.location"  @click="onShowMap(item)" )
              v-icon.mr-2( :size="20") place
              | {{item.location.lon.toFixed(4)}}, {{item.location.lat.toFixed(4)}}

      template( v-slot:item.action="{ item }")
        v-row.mr-5(justify="end")
          more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')
    map-dialog.mapDialog(v-show="coordinates"  @closeMap="onCloseMap" :coordinates="coordinates" :radius="radius" :label="mapLabel" :zoom="zoom")


</template>
<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import MapDialog from "@/modules/global/components/table/MapDialog";
import {searchMixin} from "@/mixin/searchListMixin";

export default {
  props: {
    search: String,
    selectedItems: Array,
    group: String,
  },
  mixins: [searchMixin],
  components: {
    MoreDropDownMenu,
    MapDialog
  },
  data: () => ({
    footerProps: {
      "items-per-page-options": [5, 10, 20, 50, 100, 1000],
      pagnation: {itemsPerPage: 20}
    },
    isLoadingTable: false,
    placesLoaded:false,
    groupsLoaded: false,
    coordinates: null,
    radius: null,
    mapLabel: null,
    zoom: 0,
    headers: [
      {
        text: "Reference ID",
        value: "reference_id"
      },
      {
        text: "Organization",
        value: "name"
      },
      {text: "Group", value: "group"},
      {text: "Contacts", value: "phone"},
      {text: "Supplier Type", value: "for_fuel"},
      {text: "Description", value: "description"},
      {text: "Places", value: "places_id"},
      {text: "Action", value: "action", align: "right"}
    ],

    loading: false,
    selected: [],

    menuitems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ]
  }),

  computed: {
    getData: function () {
      return this.$store.getters["merchant/getFilterByGroup"](this.group)
    },
    selectedPlaces: {
      get: function () {
        return this.$store.getters["merchant/getSelectedMerchants"];
      },
      set: function (value) {
        this.$store.commit("merchant/setSelectedMerchants", value);
      }
    },
  },
  methods: {
    customerModuleFilter: function (value, search, item) {
      let isFound = false;
      let patt = new RegExp(search, "i");
      // Search the Object
      isFound = this.searchObject(item, patt, isFound);

      // Search Group
      isFound = this.searchObject(item.group, patt, isFound);

      return isFound;
    },

    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit(
              "merchant/setCurrentIndexByID",
              value.theObject.id
          );
          this.$router.push({
            name: "merchantDetailsForm",
            params: {contactID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          confirm(
              "Are you sure you want to delete the supplier: " +
              value.theObject.name
          ) &&
          this.$store.dispatch("merchant/deleteMerchant", value.theObject.id);
          break;
      }
    },

    onShowMap(item) {
      console.log(item.location)
      this.coordinates = item.location;
      this.mapLabel = item.name;
      this.radius = item.radius / 1000;
    },
    onCloseMap() {
      this.coordinates = null;
    },
    getGroup(groupID) {
      return (this.$store.getters["settings/groupSettings/getGroupByID"](groupID)).name
    }
  },

  mounted() {
    this.isLoadingTable = true;


    if (this.$store.state.merchant.currentIndex == -1) {
      // load Data
      this.$store.dispatch("merchant/loadAllMerchants").then(() => {
        this.isLoadingTable = false;
      });
    }else{
      this.isLoadingTable = false;
    }




    // loadGroups
    if (this.$store.state.settings.groupSettings.group_data.length < 2) {
      // load Data
      this.$store.dispatch("settings/groupSettings/loadAllGroups").then(() => {
        this.groupsLoaded = true;
      });
    }else{
      this.groupsLoaded = true;
    }

    // Load Places
    if (this.$store.state.place.place_data.length < 2) {
      this.$store.dispatch("place/loadAllPlaces").then(() => {
        this.placesLoaded = true;
      });
    } else {
      this.placesLoaded = true;
    }
  }


};
</script>
<style lang="stylus" scoped>
.mapDialog {
  display: block;
  position: fixed;
  width: 50%;
  z-index: 100;
  top: 20%;
  left: calc(25%);
}

</style>

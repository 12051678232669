<template lang="pug">
  .fill-height(v-if="currentPathCoordinates")
    .mapContainer()
      #map(:style="{height:`${$store.state.main_height-120}px`}" :key="mapKey" ref="mapOsrm")
    v-btn#extendsButton(fab small color="primary" @click="zoomExtendMarkers")
      v-icon() center_focus_weak

</template>

<script>
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
export default {

  data:()=>({
    geoPath: {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: [
            [36.8371433, -1.32593],
            [36.8360433, -1.32593],
          ],
        },
      },
    },

    mapKey:0,
    pZoom: 17,
    center: {lng: 36.74372678308009, lat: -1.2878468314658136},
    accessToken:
        "pk.eyJ1IjoibXVnYW1iaWtpbWF0aGkiLCJhIjoiY2p0OWNjaGZxMDdsdjQ0cXZjZTRhZnA0aSJ9.3b3qa7uHHInxWL3TBiMsSQ",
    mapStyle: "mapbox://styles/mapbox/streets-v10",
    mapLoaded: false,
    placeArray: [],
    bboxOffset: 0.005,


  }),

  mounted() {
    this.$nextTick(() => {
      this.mapOsrm = null;
      this.createMap();
    });
  },

  watch:{
    currentPathCoordinates:function(value){
      if ( typeof(value)!="undefined" && this.mapLoaded) {
        this.drawLine();

        this.drawMarker();

      }

    },

    getSelectedWaypoint:function(newValue){
      this.hideAllPopUps();
      if(newValue != null){
        let theMarker = this.osrmMarkerList.filter(element => element.id == newValue)[0]
        theMarker.togglePopup();

        // zoom to waypoint
        this.zoomToWaypoint(theMarker);
      }
    }
  },

  computed: {
    currentPathCoordinates() {
      return this.$store.getters["routing/getCurrentPathCoordinates"];
    },
    currentOrderDetails(){
      return this.$store.getters["routing/getCurrentRouting"].order_details;
    },

    getSelectedWaypoint(){
      return this.$store.state.routing.selectedWaypoint;
    },
    routeLocked:function(){
      return this.$store.getters["routing/getCurrentRouting"].locked;
    },


  },


  methods:{
    createMap: function () {

      mapboxgl.accessToken =
          "pk.eyJ1IjoibXVnYW1iaWtpbWF0aGkiLCJhIjoiY2p0OWNjaGZxMDdsdjQ0cXZjZTRhZnA0aSJ9.3b3qa7uHHInxWL3TBiMsSQ";
      this.mapOsrm = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v10",
        minzoom: 1.3,
        center: [36.8371433, -1.32593], // Nairobi
        zoom: 16,
      });

      this.mapOsrm.on("load", this.onLoadMap);
    },



    onLoadMap: function () {
      this.mapOsrm.addSource("route_source", this.geoPath);
      this.mapOsrm.addSource("arrow_source", this.geoPath);


      let zMap = this.mapOsrm;


           this.mapOsrm.loadImage("/static/web/arrow.png", function (err, image) {
        // Throw an error if something went wrong
        if (err) throw err;

        // Declare the image
        zMap.addImage("pattern", image);

        // Use it
        zMap.addLayer({
          id: "pattern-layer",
          type: "line",
          source: "arrow_source",
          paint: {
            "line-pattern": "pattern",
            "line-width": 15,
          },
        });
      });



      this.mapOsrm.addLayer({
        id: "route_layer",
        type: "line",
        source: "route_source",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": this.$vuetify.theme.themes.light.primary,

          "line-width": 3,
        },
      });








      // drawLine
      this.drawLine();
      this.drawMarker();


      this.mapLoaded = true;
    },




    drawLine: function(){
      console.log("drawing line")

      let g = turf.lineString(this.currentPathCoordinates);

      this.mapOsrm.getSource("route_source").setData(g);
      this.mapOsrm.getSource("arrow_source").setData(g);

      this.onFitToLine(g);
    },



    drawMarker: function(){
      // remove all markers
      if(this.osrmMarkerList != null){
        this.osrmMarkerList.forEach(marker => {
          marker.remove();
        });
      }

      // remove all popups
      if(this.osrmPopUpList != null){
        this.osrmPopUpList.forEach(popUp => {
          popUp.remove();
        })
      }



      this.osrmMarkerList = [];
      this.osrmPopUpList = [];


      // add the markers
      this.currentOrderDetails.forEach(element =>{
        this.addMarker(element);
      })
    },



    addMarker: function (element) {
      let popUp = new mapboxgl.Popup({offset: 25});
      popUp.setHTML(`<h3>${this.getIndex(element) + 1}: ${element.place.name}</h3>`);
      popUp.setLngLat([element.place.location.lon, element.place.location.lat])
      popUp.id = this.getIndex(element);
      popUp.addTo(this.mapOsrm);

      this.osrmPopUpList.push(popUp);

      let marker = new mapboxgl.Marker({
        color: this.$vuetify.theme.themes.light.primary,
      });
      marker.setLngLat([element.place.location.lon, element.place.location.lat]);
      marker.setPopup(popUp);
      marker.id = this.getIndex(element);
      marker.addTo(this.mapOsrm);
      //-- add marker to array markerList
      this.osrmMarkerList.push(marker);
    },


    onFitToLine:function(theLineString){
      var bbox = turf.bbox(theLineString);
      bbox = [
        bbox[0] - this.bboxOffset,
        bbox[1] - this.bboxOffset,
        bbox[2] + this.bboxOffset,
        bbox[3] + this.bboxOffset,
      ];
      this.mapOsrm.fitBounds(bbox);
    },

    onFitToMapMarkers: function () {
      let gPath = turf.lineString(this.placeArray);

      // console.log(gPath);

      var bbox = turf.bbox(gPath);

      bbox = [
        bbox[0] - this.bboxOffset,
        bbox[1] - this.bboxOffset,
        bbox[2] + this.bboxOffset,
        bbox[3] + this.bboxOffset,
      ];
      this.mapOsrm.fitBounds(bbox);
    },






    zoomExtendMarkers:function(){
      let g = turf.lineString(this.currentPathCoordinates);
      this.onFitToLine(g);

      // console.log(this.mapOsrm);
      // var img = this.mapOsrm.getCanvas().toDataURL('image/png',0.92)
      //
      // window.location.href=img;
    },

    zoomToWaypoint:function(theMarker){

    this.mapOsrm.flyTo({
        zoom: 15,
        center: theMarker.getLngLat(),
        essential:true,
      })
    },

    hideAllPopUps:function(){
      this.osrmMarkerList.forEach(marker =>{
        if(marker.getPopup().isOpen() ==true){
          marker.togglePopup();
        }
      })
    },

    showAllPopUps:function(){
      this.osrmMarkerList.forEach(marker =>{
        if(marker.getPopup().isOpen() == false){
          marker.togglePopup();
        }
      })
    },

    getIndex:function(element){
      if(this.routeLocked){
        return element.waypoint_index;
      }else{
        return element.order_id;
      }
    },


  }
}
</script>

<style scoped>

</style>
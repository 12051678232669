<template lang="pug">
  div
    v-divider
    v-container.white.pa-2(fluid)
      v-layout.my-2.pr-4(row, wrap, align-center)
        h2.mx-4 Customer Organizations
        v-spacer
        v-btn.ma-2(small outlined color="primary" :disabled="$store.state.customer.customer_data.length > 2" @click="onRefresh")
          v-icon.mr-2(small) sync
          | Refresh
        .text-xs-center

          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small,outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item-group(color="primary")
                v-list-item(v-for="(item,index) in items", :key="index" @click="onAction(item.value)")
                  v-list-item-title() {{ item.title }}
          v-btn.ma-2(small, color='primary')
            v-icon.pr-2(dark) search
            | Search
          v-btn.ma-2(small color="primary", @click="openEdit") Add New Customer
    v-divider

    v-layout.ma-4(row align-center)
      v-row.fill-width()
        .d-flex.mx-4.fill-width()
          .d-flex.align-center.fill-width()
            v-text-field.mr-3(v-model.sync='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" style="max-width:300px")
            v-icon(v-if="search.length > 0" color="primary" outlined small @click="search=''") close
            v-select.ml-6(:items='$store.state.settings.groupSettings.group_data', item-text='name', item-value='id', @focus='onGroupSelectFocus', prepend-icon='group', v-model='group', label='Group' clearable style="max-width:350px")
            v-spacer()
            v-btn.ml-4(v-if="this.$store.getters['customer/getSelectedCustomers'].length" color="primary" outlined @click="clearAllSelection") Clear All {{this.$store.getters['customer/getSelectedCustomers'].length}} Selected Customers

    v-divider
    .grey.lighten-4.py-3
    v-divider
    customers-table(:search="searchFilter" :group="group")
    v-dialog(v-model="dialog" width="800" :persistent="true")
      import-data-dialog(@closeImportDialog="dialog=false" :hasGroup="true")
    export-data-dialog(:fileExport="fileExport" :isFinished="isDownloadFinished" v-bind:show="showExportDialog" @closeExportDialog="showExportDialog = false;" @download="onDownload" )
</template>

<script>
import CustomersTable from "@/modules/contacts/customers/components/tables/CustomersTable";
import ImportDataDialog from "@/modules/global/components/dialog/ImportDataDialog";
import Repository from "@/repository";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog";


export default {
  components: {CustomersTable, ImportDataDialog, ExportDataDialog,},

  data: () => ({
    isDownloadFinished: false,
    fileLink: null,
    showExportDialog: false,
    fileExport: "",
    dialog: false,
    active: true,
    tags: ["open", "overdue", "resolved"],
    search: "",
    searchFilter: "",
    group: null,
    items: [
      // {
      //   title: "Export Excel",
      //   value: "export_excel"
      // },
      {
        title: "Import CSV",
        value:"import_csv"
      },
      {
        title: "Export CSV",
        value: "export_csv"
      },
      {
        title:"Delete Selected",
        value: "delete_selected"
      }
    ],



  }),

  created: function() {
    this.debouncedGetAnswer = this._.debounce(this.getResult, 1000);
  },
  watch:{
    search:function(value){
      this.debouncedGetAnswer();
      console.log(value)
    }
  },


  mounted() {
  },
  methods: {
  getResult: function() {
    this.searchFilter = this.search
    console.log("the anser is here")
  },
    openEdit: function () {
      this.$store.dispatch("customer/addCustomer").then(() => {
        let currentObject = this.$store.getters["customer/getCurrentCustomer"];
        this.$router.push({
          name: "customerDetailsForm",
          params: {contactID: currentObject.id, customerID: currentObject.id}
        }).catch(()=>{});
      });
    },

    showDialog: function () {
    },

    closeDialog: function () {
      console.log("close the dialog");
    },
    onAction: function (value) {
      console.log(value)
      switch (value) {
        case "import_csv":
          this.dialog = true;
          break;
        case "export_csv":
          console.log("Download template")
          this.fileExport = "CSV";
          this.downloadFile("/report/customersCSV");
          this.showExportDialog = !this.showExportDialog;
          break;
        case "export_excel":
          this.fileExport = "Excel";
          this.downloadFile("/report/Excel/customer_list");
          this.showExportDialog = !this.showExportDialog;
          break;
        case "delete_selected":
          var deletedIds = [];

          this.$store.getters["customer/getSelectedCustomers"].forEach((element) => {
            deletedIds.push(element.id);
          })
          console.log("---------------------")
          console.log(deletedIds)
          confirm(`Are you sure you want to delete this ${deletedIds.length} item?`) &&
          this.$store.dispatch("customer/deleteSelectedCustomers", deletedIds);
          break;
      }

    },
    downloadFile: function (url) {
      this.isDownloadFinished = false;
      console.log(url)

      Repository({
        method: "GET",
        url: url,
        responseType: "blob",
      }).then((response) => {
        var fileName = response.headers["file"];
        console.log(response)

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
    },

    onDownload: function () {
      // download the file
      this.fileLink.click();
      this.showExportDialog = false;
    },
    clearAllSelection: function () {
      this.$store.commit("customer/setSelectedCustomers", []);
    },
    onGroupSelectFocus: function () {
      // Load The group List
      if (this.$store.state.settings.groupSettings.currentIndex == -1) {
        this.$store.dispatch("settings/groupSettings/loadAllGroups");
      }
    },

    onRefresh: function() {
      this.$store.dispatch("customer/loadAllCustomers");
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
<template lang="pug">
  .mapDialogMain(ref="mapDialogMain")
    v-card(elevation="12")
      v-card-title.px-4.primary.white--text()
        .fill-width.d-flex.justify-space-between()
          .d-flex.flex-column()
            div(v-if="label") {{label}}
            .caption.pink--text.text--lighten-4()
              v-icon.mr-2(color="pink lighten-4" :size="15") help_outline
              | Use the mouse wheel to zoom
          div()
            v-icon(color="white" @click="$emit('closeMap')") close
      #mapDialog(v-show="mapInitialized"  :key="theKey" :style="{'height':`${this.height}px`}")

</template>

<script>
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";

export default {
  props: {
    coordinates: {
      type: Object,
      default: function () {
        return {
          lat: -1.28113150214347, lon: 36.818166813140046
        }
      }
    }
    ,
    label: {
      type: String,
      default: "Location"
    },
    radius: Number,
    zoom:Number,
    lineArray: Array,
  },
  watch: {
    zoom: function(newVal){
      if(newVal != undefined && newVal !=null){
        console.log(newVal);
        this.mapDialog.setZoom(16+(newVal*0.1));
      }
    },
    coordinates: function (newVal) {
      if (newVal != null) {

        if(this.lineArray != null){
            this.drawLine()
          this.changeFocus(newVal)
        }else{
          this.changeFocus(newVal)
        }

      }

    }
  },

  data: () => ({
    theKey:1,
    mapDialog:null,
    mapInitialized:false,
    height: 800,
  }),

  computed: {
    geoPath: function(){
      console.log(this.lineArray)
        if (this.lineArray == null || this.lineArray.length == 0) {
          return null;
        }

        let coor = []
        this.lineArray.forEach(item => {
          coor.push([item.lon,item.lat])
        })


        let obj = {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: coor,
            },
          },
        }

        return obj;
      }
  },

  methods: {
    changeFocus: function (point) {
      this.mapDialogMarker.setLngLat([point.lon, point.lat]);
      this.mapDialog.jumpTo({
            center: [point.lon, point.lat],
            essential: true,
          }
      );

      if (this.radius) {
        console.log(this.radius);
        let circleGeom = turf.circle([point.lon, point.lat], this.radius)
        const geojsonSource = this.mapDialog.getSource("maine");
        if (geojsonSource != undefined) {
          geojsonSource.setData(circleGeom);
        }


      }

      this.mapDialog.resize();
    },


    createMap: function () {


      mapboxgl.accessToken =
          "pk.eyJ1IjoibXVnYW1iaWtpbWF0aGkiLCJhIjoiY2p0OWNjaGZxMDdsdjQ0cXZjZTRhZnA0aSJ9.3b3qa7uHHInxWL3TBiMsSQ";
      this.mapDialog = new mapboxgl.Map({
        container: "mapDialog",
        style: "mapbox://styles/mapbox/streets-v10",
        center: [this.coordinates.lon, this.coordinates.lat], // Nairobi
        zoom: 16
      });

      this.mapDialogMarker = new mapboxgl.Marker({
        color: this.$vuetify.theme.themes.light.primary,
        draggable: false
      })

      this.mapDialogMarker.setLngLat([this.coordinates.lon, this.coordinates.lat]);
      this.mapDialogMarker.addTo(this.mapDialog);



      // Load the map
      this.mapDialog.on("load", this.onLoadMap)

    },

    onLoadMap: function () {
      // RADIUS LAYER ----------------------

      let circleGeom = turf.circle([36.81816681314004, -1.28113150214347], this.radius ? this.radius : 0.0001)

      this.mapDialog.addSource('maine', {
        'type': 'geojson',
        'data': circleGeom
      });

      // Add a new layer to visualize the polygon.
      this.mapDialog.addLayer({
        'id': 'maine',
        'type': 'fill',
        'source': 'maine', // reference the data source
        'layout': {
          visibility: 'visible'
        },
        'paint': {
          'fill-color': '#ec037b', // blue color fill
          'fill-opacity': 0.2
        }
      });

      // LINE LAYER ---------------------------------------------
      let line = turf.lineString([
                [0, 0],
                [0, 0.0001],
              ])

      console.log(line)

      // Add Route Source
      this.mapDialog.addSource('route_source',{type:'geojson',data:line})
      this.mapDialog.addLayer({
        id: "route_layer",
        type: "line",
        source: "route_source",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": this.$vuetify.theme.themes.light.primary,
          "line-width": 3,
        },
      });


      // ARROW LAYER --------------------------------------------


      // add Arrow Source
      this.mapDialog.addSource('arrow_source',{type:'geojson',data:line})
      let zMap = this.mapDialog
          this.mapDialog.loadImage("/static/web/arrow.png", function (err, image) {
            // Throw an error if something went wrong
            if (err) throw err;

            // Declare the image
            zMap.addImage("pattern", image);

            // Use it
            zMap.addLayer({
              id: "pattern-layer",
              type: "line",
              source: "arrow_source",
              paint: {
                "line-pattern": "pattern",
                "line-width": 15,
              },
            });
          });



      this.drawLine();
      // Resize the map
      this.mapDialog.resize();
    },
    drawLine: function(){
      if(this.mapDialog == null || this.mapInitialized == false || this.lineArray == null) return;

      let g = turf.lineString( this.geoPath.data.geometry.coordinates);


      this.mapDialog.getSource("route_source").setData(g);
      this.mapDialog.getSource("arrow_source").setData(g);

      // this.onFitToLine(this.geoPath);
      // var bbox = turf.bbox(theLineString);
      // bbox = [
      //   bbox[0] - this.bboxOffset,
      //   bbox[1] - this.bboxOffset,
      //   bbox[2] + this.bboxOffset,
      //   bbox[3] + this.bboxOffset,
      // ];
      // this.mapRoute.fitBounds(bbox);
    },
  },
  mounted() {
    console.log(this.lineArray);
    console.log(this.zoom);
    console.log(this.coordinates);

    this.$nextTick(() => {
      console.log(this.$refs.mapDialogMain.getBoundingClientRect().width);
      console.log(this.$refs.mapDialogMain.getBoundingClientRect().height);
      this.height = this.$refs.mapDialogMain.getBoundingClientRect().height;
      this.mapInitialized = true;
      this.mapDialog = null;
      this.createMap();
    })


  },

}
</script>

<style scoped>
#mapDialog {
  width: 100%;
  height: 350px;
}
</style>
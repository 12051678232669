<template lang="pug">
div(v-if="$store.state.reminders.service_reminder.currentIndex > -1")
  v-toolbar(dense, flat)
    v-toolbar-title Service Reminder
    v-spacer

  v-container.py-2
    v-alert(v-if="error" :value="true" color="error" icon="warning") 
      div(v-for="e,index in errors" :key="index" v-text="e")

    v-layout(justify-end)
      v-btn.ma-2(small text color="grey" @click="cancel") cancel
      v-btn.ma-2(small outlined color="primary" @click="saveAdd") Save &amp; Add another
      v-btn.ma-2(small color="primary" @click="save") Save

  v-container
    v-card
      v-form(ref="form" v-model="valid" lazy-validation)
        v-container.grid-list-xl
          v-row(align="end")
            v-col(cols="12" xs="12", md="6")
              vehicles-auto-complete(v-model="vehicles_id" :multiple="true")
            v-col(cols="12" xs="12", md="6")
              autos-combo-box(v-model='service_types_id'  label="Select Service Tasks" module="service" submodule="serviceType"   actionPrefix="ServiceType")
      
          v-divider
          v-row()
            v-col(cols="12" xs="12", md="6")
              odometer-reminder(v-model="odoReminderObj")
            v-col(cols="12" xs="12", md="6")
              time-reminder(v-model="timeReminderObj")
              
          v-divider
          v-row()
            v-col(xs="12", md="6")
              contacts-auto-complete(v-model="contacts_id")
            v-col(xs="12", md="6")
              v-row()
                v-col(cols="6")
                  v-switch(label="Email Notifications" v-model="email_notifications" hint="If ON, emails are sent when reminder is 'due soon' or overdue", :persistent-hint="true")
                v-col(cols="6")
                  v-switch(label="SMS Notifications" v-model="sms_notifications" hint="If ON, sms are sent when reminder is 'due soon' or overdue", :persistent-hint="true")
          v-row
            v-col(cols="12")
              v-textarea(v-model="comments" rows=3 label="comments")

  v-container.py-2
    v-layout(justify-end)
      v-btn.ma-2(small text color="grey" @click="cancel") cancel
      v-btn.ma-2(small outlined color="primary" @click="saveAdd") Save &amp; Add another
      v-btn.ma-2(small color="primary" @click="save") Save
</template>
<script>
import VehiclesAutoComplete from "@/modules/global/components/forms/VehiclesAutoComplete";
import ContactsAutoComplete from "@/modules/global/components/forms/ContactsAutoComplete";
import AutosComboBox from "@/modules/global/components/forms/AutosComboBox";
import { createHelpers } from "vuex-map-fields";
import OdometerReminder from "@/modules/reminders/components/ui/OdometerReminder";
import TimeReminder from "@/modules/reminders/components/ui/TimeReminder";

const { mapFields } = createHelpers({
  getterType: "reminders/service_reminder/getService_reminder",
  mutationType: "reminders/service_reminder/updateService_reminder"
});

export default {
  components: {
    VehiclesAutoComplete,
    ContactsAutoComplete,
    AutosComboBox,
    OdometerReminder,
    TimeReminder
  },
  data: () => ({
    error: false,
    errors: [],
    valid: true
  }),

  computed: {
    ...mapFields([
      "vehicles_id",
      "service_types_id",
      "contacts_id",
      "start_odometer",
      "interval_odometer",
      "due_odometer",
      "interval",
      "due_date_amount",
      "start_date",
      "email_notifications",
      "sms_notifications",
      "comments"
    ]),
    timeReminderObj: {
      get: function() {
        return {
          start_date: this.start_date,
          interval: this.interval,
          due_date_amount: this.due_date_amount
        };
      },
      set: function(value) {
        console.log("setting the timeRiminder object");
        console.log(value);
        this.start_date = value.start_date;
        this.interval = value.interval;
        this.due_date_amount = value.due_date_amount;
      }
    },
    odoReminderObj: {
      get: function() {
        return {
          start_odometer: this.start_odometer,
          interval_odometer: this.interval_odometer,
          due_odometer: this.due_odometer
        };
      },
      set: function(value) {
        console.log("setting odometer obj");
        console.log(value);
        this.start_odometer = value.start_odometer;
        this.interval_odometer = value.interval_odometer;
        this.due_odometer = value.due_odometer;
      }
    }
  },

  methods: {
    validateForm: function() {
      this.error = false;
      this.errors = [];

      if (this.vehicles_id === null) {
        this.errors.push("Select a Vehicle(s)");
        this.error = true;
      }

      if (this.service_types_id.length > 0) {
        this.errors.push("Select a Service Type");
        this.error = true;
      }

      if (this.contact_id === null) {
        this.errors.push("Select a Contact(s) to send the reminder");
        this.error = true;
      }

      if (this.start_date.getTime() < new Date().getTime()) {
        this.errors.push("Enter a Date and Time in the future.");
        this.error = true;
      }

      return this.error;
    },

    cancel: function() {
      this.$router.push({ name: "reminders", params: { tabID: 0 } }).catch(()=>{});
    },

    save: function() {

            this.$store
              .dispatch("reminders/service_reminder/saveService_reminder")
              .then(() => {
                this.$router.push({ name: "reminders", params: { tabID: 0 } }).catch(()=>{});
              });


    },

    saveAdd: function() {
      // todo: Save and Add Another
    }
  },

  mounted() {
    // get the Service Type
    if (this.$store.state.service.serviceType.currentIndex === -1) {
      this.$store.dispatch("service/serviceType/loadAllServiceTypes");
    }

    if (this.$store.state.reminders.service_reminder.currentIndex === -1) {
      this.$store
        .dispatch(
          "reminders/service_reminder/fetchService_reminder",
          this.$route.params.reminderID
        )
        .then(() => {});
    }
  }
};
</script>
<style scoped lang="stylus">

.sampleColumn
  text-align center

.reminderSmallText
  font-size 12px;
  color white

.threshold
  border 1px solid #CCCCCC
  padding 12px
  border-radius 8px
  background-color #f2f2f2
</style>

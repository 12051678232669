<template lang="pug">
  div()
    v-progress-linear(v-if="isLoadingTable" indeterminate)
    div(v-if="this.$store.state.journey.currentIndex > -1")
      v-data-table.elevation-1(
        v-model="selectedJourneys"
        :search="search"
        :custom-filter="journeyModuleFilter"
        :headers='headers',
        :items='getData'
        item-key="id"
        :items-per-page="footerProps.pagnation.itemsPerPage",
        :footer-props="footerProps"
        show-select
      )
        template(v-slot:progress)
          v-progress-linear(slot="progress",height="2",:indeterminate="loading")

        template(v-slot:item.inspection_record_id="{item}")
          div()
            .caption(v-if="item.inspection_record")
              v-icon() playlist_add_check
              a.text-decoration-underline.span.ml-2(@click="clickInspection(item.inspection_record_id)") {{item.inspection_record.inspection_form_name}}
            .caption.mt-2(v-if="item.osrm")
              v-icon(:size="18") alt_route
              a.text-decoration-underline.span.ml-4(@click="clickRoute(item.osrm_id)") {{item.osrm.name}}
            .caption.mt-2()
              v-icon(:size="18") assignment_returned
              a.text-decoration-underline.ml-4(@click="clickAssignment()") Assignment

        template(v-slot:item.contact_id="{item}")
          div()
            .caption(v-if="item.contact") Requested By:
              span.grey.lighten-3.px-2.rounded.ml-2.primary--text.font-weight-bold() {{item.contact.first_name}} {{item.contact.last_name}}
            .caption.grey--text() Submitted On: {{formatDate(item.submitted_at)}}
            .ml-4(v-if="item.journey_approvals.length > 0") Approvals:
              div(v-for="(element,index) in item.journey_approvals" :key="index")
                .caption.mt-1()
                  v-icon(size="16" :color="element.approved ? 'green' : 'primary'") {{element.approved ? 'done' : 'close'}}
                  span() {{element.label}}:
                  span.grey.px-2.ml-3.rounded.white--text() {{element.first_name}} {{element.last_name}}


        template(v-slot:item.start_at="{item}")
          div()
            div()
              span.grey.px-2.pb-1.rounded.white--text.mr-2()
                v-icon.mr-1(size="14" color="white") timer
                | start:
              span() {{formatDate(item.start_at)}}
            div.mt-4()
              span.grey.px-2.pb-1.rounded.white--text.mr-2()
                v-icon.mr-1(size="14" color="white") timer
                | end:
              span.ml() {{formatDate(item.end_at)}}


        template(v-slot:item.approved_at="{item}")
          v-simple-checkbox(v-model="item.approved_at != null" disabled color="primary")


        template(v-slot:item.vehicle_id="{item}")
          vehicle-column-by-id(v-if="item.vehicle_id" :vehicle_id="item.vehicle_id")

        template(v-slot:item.name="{item}")
          div.primary--text() {{item.reference_no}}
          div() {{item.name}}


        template( v-slot:item.action="{ item }")
          v-row.mr-7(justify="end")
            more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')
</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import {searchMixin} from "@/mixin/searchListMixin";
import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById";
import moment from "moment";

export default {
  components: {
    MoreDropDownMenu,
    VehicleColumnById
  },
  props: {
    search: String,
    selectedItems: Array,
  },
  mixin: [searchMixin],
  data: () => ({
    selectedJourneys: [],

    headers: [
      {text: "Vehicle", value: "vehicle_id"},
      {text: "Name", value: "name"},
      {text: "Approved", value: "approved_at", align: "center"},
      {text: "Journey", value: "inspection_record_id"},
      {text: "Start", value: "start_at"},
      {text: "Contact", value: "contact_id"},

      {text: "Action", value: "action", align: "right"}
    ],
    footerProps: {
      "items-per-page-options": [5, 10, 20, 50, 100, 1000],
      pagnation: {itemsPerPage: 20}
    },
    isLoadingTable: false,
    loading: false,
    pagination: [5, 10, 20, 50, 100],
    rowsPerPage: 20,
    menuitems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ]

  }),
  computed: {
    getData: function () {
      return this.$store.state.journey.journey_data;
    }
  },
  methods: {
    journeyModuleFilter: function (value, search, item) {
      let isFound = false;
      let patt = new RegExp(search, "i");
      // Search the Object
      isFound = this.searchObject(item, patt, isFound);

      // Search Contact
      isFound = this.searchList(item.contact, patt, isFound);

      return isFound;
    },
    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          break;
        case "delete":
          confirm("Are you sure you want to delete this item?") &&
          this.$store.dispatch("journey/deleteJourney", value.theObject.id);
          break;
      }
    },

    clickRoute: function (theID) {
      this.$store.commit("routing/setCurrentIndexByID", theID);
      this.$router.push({
        name: "routeDetailsForm",
        params: {placeID: theID}
      }).catch(()=>{});
    },

    clickInspection: function (theID) {
      this.$router.push({name: "inspectionRecordOverview", params: {inspectionID: theID}}).catch(()=>{});
    },

    clickAssignment: function () {
      this.$router.push({name: "vehicles", params: {tabID: 1}}).catch(()=>{})
    },

    formatDate: function (theDate) {
      return moment(theDate).format("h:mm a, ddd, Do MMM YYYY")
    }
  },
  mounted() {
    if (this.$store.state.journey.currentIndex == -1) {
      this.isLoadingTable = true;
      this.$store.dispatch("journey/loadAllJourneys").then(() => {
        this.isLoadingTable = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
<template lang="pug">
  div()
    v-progress-linear(v-if="isLoadingTable" indeterminate)
    div(v-if="$store.state.customer.customer_data.length > 0")
      v-data-table.elevation-1(
        v-model="selectedCustomers"
        :headers="headers",
        :search="search",
        :items='getData'
        :custom-filter="customerModuleFilter"
        item-key="id",
        :single-select="false",
        :items-per-page="footerProps.pagnation.itemsPerPage",
        :footer-props="footerProps"
        @click:row="onClickRow"
        show-select)


        template(v-slot:item.reference_id="{item}")
          .showPointer() {{item.reference_id}}

        template(v-slot:item.name="{item}")
          .showPointer()
            .body-1 {{ item.name }}
            .d-flex(v-if="item.first_name != null")
              v-icon(size="16") person
              .caption.grey--text.text--darken-1 {{item.first_name}} {{item.last_name}}
            .div(v-if="getCustomerCustomFields.length > 0")
              .d-flex(v-for="(element,index) in getCustomerCustomFields" :key="index")
                .caption.grey--text.text--darken-1(v-if="item.details") {{element.label}}: {{item.details[element.label_id]}}


        template(v-slot:item.phone="{item}")
          .caption()
            div() {{item.phone}}
            div() {{item.website}}
            div()
              a(:href="'mailto:'+item.email") {{ item.email }}

        template(v-slot:item.contacts="{item}")
          .caption(v-for="(contact,index) in item.contacts" :key="index")
            | - {{contact.first_name}} {{contact.last_name}}


        template(v-slot:item.group="{item}")
          div(v-if="item.group_id != null && groupsLoaded")
            div(v-if="$store.getters['settings/groupSettings/getGroupByID'](item.group_id)") {{$store.getters["settings/groupSettings/getGroupByID"](item.group_id).name}}


        template(v-slot:item.description="{item}")
          div() {{item.description}}

        template(v-slot:item.places_id="{item}")
          div(v-if="item.places_id != null && placesLoaded")
            div(v-for="(id,index) in item.places_id" :key="index")
              .caption() {{$store.getters["place/getPlaceByID"](id).name }}



        template(v-slot:item.street_address="{item}")
          div()
            div() {{item.street_address}}
            a()
              .caption(v-if="item.location"  @click="onShowMap(item)" )
                v-icon.mr-2( :size="20") place
                | {{item.location.lon.toFixed(4)}}, {{item.location.lat.toFixed(4)}}


        template( v-slot:item.action="{ item }")
          v-row.mr-5(justify="end")
            more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')
      map-dialog.mapDialog(v-show="coordinates"  @closeMap="onCloseMap" :coordinates="coordinates" :radius="radius" :label="mapLabel" :zoom="zoom")

</template>
<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import MapDialog from "@/modules/global/components/table/MapDialog";
import {searchMixin} from "@/mixin/searchListMixin";

export default {
  props: {
    search: String,
    group: String,
  },
  components: {
    MoreDropDownMenu,
    MapDialog
  },
  mixins: [searchMixin],
  data: () => ({

    footerProps: {
      "items-per-page-options": [5, 10, 20, 50, 100, 1000],
      pagnation: {itemsPerPage: 20}
    },
    isLoadingTable: false,
    placesLoaded: false,
    groupsLoaded: false,
    coordinates: null,
    radius: null,
    mapLabel: null,
    zoom: 0,
    headers: [
      {
        text: "Reference ID",
        value: "reference_id"
      },
      {
        text: "Organization",
        value: "name"
      },
      {text: "Group", value: "group"},
      {text: "Contacts", value: "phone"},
      {text: "Description", value: "description"},
      {text: "Places", value: "places_id"},
      {text: "Action", value: "action", align: "right"}
    ],
    loading: false,
    selected: [],
    menuitems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ]
  }),

  computed: {
    getCustomerCustomFields(){
      return this.$store.getters["profile/getCustomFields"]("customer");
    },

    getData: function () {
      return this.$store.getters["customer/getFilterByGroup"](this.group)
    },

    selectedCustomers: {
      get: function () {
        return this.$store.getters["customer/getSelectedCustomers"];
      },
      set: function (value) {
        this.$store.commit("customer/setSelectedCustomers", value);
      }
    },
  },

  methods: {
    onClickRow: function(value){
      this.$store.commit(
          "customer/setCurrentIndexByID",
          value.id
      );
      this.$router.push({
        name: "customerDashboard",
        params: {contactID: value.id}
      }).catch(()=>{});
    },

    customerModuleFilter: function (value, search, item) {
      let isFound = false;
      let patt = new RegExp(search, "i");
      // Search the Object
      isFound = this.searchObject(item, patt, isFound);

      // Search Group
      isFound = this.searchObject(item.group, patt, isFound);

      return isFound;
    },

    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit(
              "customer/setCurrentIndexByID",
              value.theObject.id
          );
          this.$router.push({
            name: "customerDetailsForm",
            params: {contactID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          confirm(
              "Are you sure you want to delete the customer: " +
              value.theObject.name
          ) &&
          this.$store.dispatch("customer/deleteCustomer", value.theObject.id);
          break;
      }
    },
    onShowMap(item) {
      console.log(item.location)
      this.coordinates = item.location;
      this.mapLabel = item.name;
      this.radius = item.radius / 1000;
    },
    onCloseMap() {
      this.coordinates = null;
    },

 
  },

  async mounted()  {
   this.isLoadingTable = true;


    if (this.$store.state.settings.groupSettings.currentIndex == -1) {
      this.groupsLoaded = true;
      this.$store.dispatch("settings/groupSettings/loadAllGroups").then(()=>{
        if (this.$store.state.customer.currentIndex == -1) {
          // load Data
          this.$store.dispatch("customer/loadAllCustomers").then(() => {
            console.log("loaded customer data")
            this.isLoadingTable = false;
          });
        }else{
          this.isLoadingTable = false;
        }
      })

    }else{
      if (this.$store.state.customer.currentIndex == -1) {
        // load Data
        this.$store.dispatch("customer/loadAllCustomers").then(() => {
          console.log("loaded customer data")
          this.isLoadingTable = false;
        });
      }else{
        this.isLoadingTable = false;
      }
      this.groupsLoaded = true;

    }




    if (this.$store.state.place.place_data.length < 2) {
      this.$store.dispatch("place/loadAllPlaces").then(() => {
        this.placesLoaded = true;
      });
    } else {
      this.placesLoaded = true;
    }



  // load Places
    if (this.$store.state.place.place_data.length < 2) {
      this.$store.dispatch("place/loadAllPlaces").then(() => {
        this.placesLoaded = true;
      });
    } else {
      this.placesLoaded = true;
    }
  }



};
</script>
<style lang="stylus" scoped>
.mapDialog {
  display: block;
  position: fixed;
  width: 50%;
  z-index: 100;
  top: 20%;
  left: calc(25%);
}

.showPointer {
  cursor pointer
}

</style>

import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const CUSTOMFIELD_API = "/customField";

// ----------------------- STATE ------------------------- //
const state = {
    customfield_data: [],
    currentIndex: -1,
    referenceItems: [
        {label: "Vehicle", id: "vehicle"},
        {label: "Contact", id: "contact"},
        {label: "Journey Approval Contact", id: "journey_approval"},
        {label: "Merchant", id: "merchant"},
        {label: "Customer", id: "customer"},
        {label: "Fuel", id: "fuel"},
        {label: "Issue", id: "issue"},
        {label: "Service", id: "service"},
        {label: "Service Reminder", id: "service_reminder"},
        {label: "Contact Renewal", id: "contact_renewal"},
        {label: "Vehicle Renewal", id: "vehicle_renewal"},
        {label: "Expense", id: "expense"},
        {label: "Billing", id: "billing"},
        {label: "Item Part", id: "part"},
        {label: "Gps", id: "gps"},
        {label: "Inspection", id: "inspection"},
        {label: "Place", id: "place"},
        {label: "Inventory", id: "inventory"},
    ],
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getCustomField: state => getField(state.customfield_data[state.currentIndex]),

    getCurrentCustomField: state => {
        return state.customfield_data[state.currentIndex];
    },

    getReferenceLabel: state => (referenceID) => {
        if (referenceID == null) {
            return null
        } else {
            return state.referenceItems.find(element => element.id === referenceID).label
        }

    },

    getCustomFieldIndexByID: (state) => (id) => {
        return state.customfield_data.findIndex((element) => element.id == id);
    },
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateCustomField(state, payload) {
        updateField(state.customfield_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.customfield_data.findIndex(
            customfield => customfield.id == theID
        );

        console.log(`currentIndex : ${state.currentIndex}`)
    },


    loadAllCustomFields(state, payload) {
        state.customfield_data = payload.data;
        state.currentIndex = 0;
    },

    addCustomField(state, payload) {
        state.customfield_data.push(payload.data);
        state.currentIndex = state.customfield_data.length - 1;
    },

    saveCustomField(state, payload) {
        state.customfield_data[state.currentIndex] = payload.data;
    },

    fetchCustomField(state, payload) {
        if (state.currentIndex == -1) {
            state.customfield_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.customfield_data[state.currentIndex] = payload.data;
        }
    },

    deleteCustomField(state, payload) {
        state.currentIndex = state.customfield_data.findIndex(
            customfield => customfield.id == payload.data.id
        );
        state.customfield_data.splice(state.currentIndex, 1);
    },
    reset(state) {
        state.customfield_data = []
        state.currentIndex = -1
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllCustomFields({commit}) {
        try {
            commit("loadAllCustomFields", await Repository.get(`${CUSTOMFIELD_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addCustomField({commit}) {
        await Repository.get(`${CUSTOMFIELD_API}/new`)
            .then(response => {
                commit("addCustomField", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveCustomField({state, commit}) {
        try {
            commit(
                "saveCustomField",
                await Repository.post(
                    `${CUSTOMFIELD_API}`,
                    state.customfield_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchCustomField({commit}, payloadID) {
        try {
            commit(
                "fetchCustomField",
                await Repository.get(`${CUSTOMFIELD_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteCustomField({commit}, payloadID) {
        try {
            commit(
                "deleteCustomField",
                await Repository.delete(`${CUSTOMFIELD_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchCustomFields({commit}, searchTerm) {
        try {
            commit(
                "loadAllCustomFields",
                await Repository.get(`${CUSTOMFIELD_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

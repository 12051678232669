<template lang="pug">
  div(:style="getStyle" @mouseover="showEdit=true" @mouseout="showEdit=false")
    v-btn.ml-2(v-if="canDownload" text icon color="primary"  @click="onClick")
      v-icon(small) get_app
    v-btn(v-show="showEditButton()" @click="navigateTo()" text icon color="primary")
       v-icon(small) edit
</template>
<script>
import Repository from "@/repository";

export default {
  components: {},
  props: {
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },
    src: String,

    route: {
      type: Object,
      default: null
    },

    radius: {
      type: Number,
      default: 6
    },

    canDownload:{
      type: Boolean,
      default: false,
    },

    useThumbnail:{
      type: Boolean,
      default: true,
    },

    position:{
      type:String,
      default: "center"
    }
  },
  data: () => ({
    showEdit: false,
    resultObject: null
  }),

  computed: {
    getStyle: function() {
      var styleObject = {
        border: "1px solid #E5E5E5",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "background-position": this.position,
        display: "flex",
        "justify-content": "end",
        "align-items": "end",
        "border-radius": `${this.radius}px`,
        width: this.width + "px",
        height: this.height + "px",
        "font-size": "10px"
      };
      if (this.resultObject) {
        // path string
        let path = `/media/${this.resultObject.account_id}/${this.resultObject.thumbnail}`;

        // set the background
        styleObject["background-image"] = 'url("' + path + '")';
        styleObject["border-style"] = "solid";
        styleObject["border-width"] = "1px";
      } else {
        styleObject["border-style"] = "dashed";
        styleObject["border-width"] = "2px";
      }

      return styleObject;
    }
  },

  watch: {
    src: function(newValue) {
      console.log(`Watched hahahaha!! ${newValue}`);
      if (newValue) {
        this.loadImage();
      } else {
        this.resultObject = null;
      }
    }
  },

  methods: {
    loadImage: function() {
      Repository.get(`/upload/blob/${this.src}`)
        .then(payload => {
          this.resultObject = payload.data;
        })
        .catch(payload => {
          console.log(payload.data);
        });
    },

    navigateTo: function() {
      if (this.route) {
        this.$router.push(this.route).catch(()=>{});
      }
    },

    showEditButton: function() {
      if (this.route && this.showEdit) {
        return true;
      } else {
        return false;
      }
    },

    onClick:function() {
      this.fileLink = document.createElement("a");

      // this.fileLink.href = fileURL;
      if(this.useThumbnail && this.resultObject.thumbnail != null){
        this.fileLink.href = `/media/${this.resultObject.account_id}/${this.resultObject.thumbnail}`;
      }else{
        this.fileLink.href = `/media/${this.resultObject.account_id}/${this.resultObject.file_name}`;
      }

      this.fileLink.setAttribute("download", this.resultObject.original_name);
      document.body.appendChild(this.fileLink);
      this.fileLink.click();
    },




  },

  mounted() {
    if (this.src) {
      this.loadImage();
    }
  }
};
</script>
<style scoped lang="stylus"></style>

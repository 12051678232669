import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const ROUTING_API = "/osrm";

// ----------------------- STATE ------------------------- //
const state = {
    routing_data: [],
    selectedPlaces: [],
    currentIndex: -1,
    startPlace: null,
    endPlace: null,
    dateRange: {start: "2020-03-23 12:00 am", end: "2020-03-27"},
    tspResponse: null, // result from Traveling Salesman Problem
    selectedWaypoint: null,
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getRouting: (state) => getField(state.routing_data[state.currentIndex]),

    getCurrentRouting: (state) => {
        return state.routing_data[state.currentIndex];
    },

    getDateRange: (state) => {
        return state.dateRange;
    },
    getSelectedPlace: (state) => state.selectedPlaces,

                    getCurrentPathCoordinates: (state) => {
        if (state.routing_data[state.currentIndex].tsp_details == null){
            return null;
        }else{
            if(state.routing_data[state.currentIndex].locked == true){
                return state.routing_data[state.currentIndex].tsp_details.trips[0].geometry.coordinates;
            }else{
                return state.routing_data[state.currentIndex].tsp_details.routes[0].geometry.coordinates;
            }

        }
    }

};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    setSelectedPlaces(state, payload) {
        state.selectedPlaces = payload;
    },

    setSelectedWaypoint(state, index){
        state.selectedWaypoint = index;
    },

    updateRouting(state, payload) {
        updateField(state.routing_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.routing_data.findIndex(
            (routing) => routing.id == theID
        );
    },


    deleteFromSelected(state, theID){
        let i = state.selectedPlaces.findIndex(o => o.id == theID)
        if (i>-1){
            state.selectedPlaces.splice(i,1);
        }
    },

    deleteSelectedByIndex(state, index){
        state.selectedPlaces.splice(index,1)
    },

    unshiftIntoSelected(state,place){
        state.selectedPlaces.unshift(place)
    },
    pushIntoSelected(state,place){
        state.selectedPlaces.push(place)
    },

    loadAllRoutings(state, payload) {
        state.routing_data = payload.data;
        state.currentIndex = 0;
    },

    addRouting(state, payload) {
        state.routing_data.push(payload.data);
        state.currentIndex = state.routing_data.length - 1;
    },

    saveRouting(state, payload) {
        state.routing_data[state.currentIndex] = payload.data;
    },

    fetchRouting(state, payload) {
        if (state.currentIndex == -1) {
            state.routing_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.routing_data[state.currentIndex] = payload.data;
        }
        state.selectedPlaces = payload.data.order_details;

    },

    deleteRouting(state, payload) {
        state.currentIndex = state.routing_data.findIndex(
            (routing) => routing.id == payload.data.id
        );
        state.routing_data.splice(state.currentIndex, 1);
    },

    duplicateRouting(state, payload) {
        state.routing_data.splice(state.currentIndex, 0,payload.data)
    },
    setDateRange(state, payload) {
        state.dateRange = payload;
    },

    loadTSP(state, payload) {
        state.tspResponse = payload;
    },

    setNewOrderDetails(state, payload){
        console.log(`removed: ${payload.removedIndex} added: ${payload.addedIndex} start_place_id: ${ state.routing_data[state.currentIndex].start_place_id} `)
        let tempOrder = state.routing_data[state.currentIndex].order_details;
        let itemToAdd
        if(payload.removedIndex !== null){
            itemToAdd = tempOrder.splice(payload.removedIndex,1)[0];

        }
        if(payload.addedIndex !== null){
            tempOrder.splice(payload.addedIndex,0,itemToAdd)
        }

        state.routing_data[state.currentIndex].order_details = tempOrder;
        state.selectedPlaces = tempOrder;
    },

    reset(state) {
        state.routing_data = []
        state.selectedPlaces = []
        state.currentIndex = -1
        state.startPlace = null
        state.endPlace = null
        state.dateRange = {start: "2020-03-23 12:00 am", end: "2020-03-27"}
        state.tspResponse = null // result from Traveling Salesman Problem
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadTSP({commit}, osrmObj) {
        await Repository.post(`${ROUTING_API}/LoadTsp`, osrmObj)
            .then((response) => {
                commit("loadTSP", response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    async loadAllRoutings({commit}) {
        try {
            commit("loadAllRoutings", await Repository.get(`${ROUTING_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addRouting({commit}) {
        await Repository.get(`${ROUTING_API}/new`)
            .then((response) => {
                commit("addRouting", response);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    async saveRouting({state, commit}) {
        
        try {
            commit(
                "saveRouting",
                await Repository.post(
                    `${ROUTING_API}`,
                    state.routing_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchRouting({commit}, payloadID) {
        try {
            commit(
                "fetchRouting",
                await Repository.get(`${ROUTING_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteRouting({commit}, payloadID) {
        try {
            commit(
                "deleteRouting",
                await Repository.delete(`${ROUTING_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async duplicateRouting({state, commit}) {
        try {
            commit(
                "duplicateRouting",
                await Repository.post(
                    `${ROUTING_API}/duplicate`,
                    state.routing_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchRoutings({commit}, searchTerm) {
        try {
            commit(
                "loadAllRoutings",
                await Repository.get(`${ROUTING_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    },
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

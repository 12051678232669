import { render, staticRenderFns } from "./MerchantsTable.vue?vue&type=template&id=27a1f452&scoped=true&lang=pug"
import script from "./MerchantsTable.vue?vue&type=script&lang=js"
export * from "./MerchantsTable.vue?vue&type=script&lang=js"
import style0 from "./MerchantsTable.vue?vue&type=style&index=0&id=27a1f452&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a1f452",
  null
  
)

export default component.exports
<template lang="pug">
  v-data-table(
    :headers="headers"
    :search="search"
    :custom-filter="messageModuleFilter"
    :items="getData"
    item-key="id"
    :loading="isLoading"
    :single-select="false"
    show-select
    v-model="selectedItems"
  )

    template(v-slot:progress)
      v-progress-linear(v-slot="progress"  height="2" :indeterminate="isLoading")

    template(v-slot:item.vehicle_from_id="{item}")
      vehicle-column-by-id(v-if="item.vehicle_from_id" :vehicle_id="item.vehicle_from_id" size="small")

    template(v-slot:item.contact_from_id="{item}")
      div(v-if="item.contact_from_id && getContact(item.contact_from_id)")
        div(v-if="getContact(item.contact_from_id) != null")
          div() {{getContact(item.contact_from_id).first_name}}
          div() {{getContact(item.contact_from_id).last_name}}


    template(v-slot:item.contacts_id="{item}")
      div(style="max-width:180px")
        .d-flex.flex-wrap()
          v-tooltip(v-for="(contact_id,index) in item.contacts_id" :key="index" bottom)
            template(v-slot:activator="{ on, attrs }")
              .contactRound.grey.white--text.pointer(v-bind="attrs"  v-on="on" v-if="getContact(contact_id) != null")  {{getContact(contact_id).first_name[0]}}{{getContact(contact_id).last_name[0]}}
            div(v-if="getContact(contact_id) != null") {{getContact(contact_id).full_name}}

    template(v-slot:item.message="{item}")
      div(@click="onOpen(item)")
        template(v-if="item.status")
          div(v-html="item.title" :style="item.status==1?{'font-weight':'bold'}:{'font-weight':'normal'}")
        template(v-else)
          .font-weight-bold() {{item.title}}
        .caption(v-text="item.message")

    template(v-slot:item.created_at="{item}")
      div(style="min-width:220px") {{getDate(item.created_at)}}

    template( v-slot:item.action="{ item }")
      v-row.mr-5(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')

</template>
<script>
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById";
import moment from "moment";
import { searchMixin } from "@/mixin/searchListMixin";
export default {
  components: {
    VehicleColumn,
    MoreDropDownMenu,
    VehicleColumnById
  },
  mixins: [searchMixin],
  props: ["search","group"],
  data: () => ({
    isLoading: true,
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ],


    menuitems: [
      {
        title: "Send Notification",
        icon: "notifications",
        action: "notification"
      },
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" },
      { title: "View Full History", icon: "history", action: "history" }
    ]
  }),

  computed:{
    getData: function () {
      return this.$store.getters["messages/getFilterByGroup"](this.group)
    },
    selectedItems: {
      get: function(){
        return this.$store.state.messages.selectedItems;
      },
      set: function(value){
        this.$store.commit("messages/setSelectedItems",value)
      }
    },
    isAdministrator: function(){
      return this.$store.state.profile.userInfo.is_administrator;
    },

    headers : function(){
      let headerList = [
        {
          text: "From Vehicle",
          value: "vehicle_from_id"
        },
        {
          text: "From Contact",
          value: "contact_from_id"
        },

        { text: "Message", value: "message" },
        { text: "Sent", value: "created_at" },
        { text: "Action", value: "action", align: "right" }
      ];


      if(this.isAdministrator){
        headerList.splice(2,0,{
          text: "To",
          value: "contacts_id"
        })
      }
      return headerList;
    }
  },
  methods: {
    getContact(contact_id){
      let result =  this.$store.getters["contacts/getContactByID"](contact_id)
      if(typeof result == "undefined"){
        return null
      }else{
        return result;
      }
    },

    getDate(theDate){
      return moment(theDate).format("h:mm a - ddd, Do MMM YYYY")
    },

    onOpen: function(item) {
      this.$store.commit("messages/setCurrentIndexByID",item.id)
      this.$store.commit("showRightDrawer", {title: "Messages", width: 600, component: "RightDrawerMessageInfo"});
      this.$store.commit("messages/markCurrentAsRead");
    },

    onClick: function(value) {
      console.log(value);
      switch (value.action) {
        case "edit":
          this.$store.commit(
              "reminders/vehicle_renewal/setCurrentIndexByID",
              value.theObject.id
          );
          this.$router.push({
            name: "vehicleRenewalForm",
            params: { reminderID: value.theObject.id }
          }).catch(()=>{});

          break;
        case "delete":
          this.$store.dispatch(
              "reminders/vehicle_renewal/deleteVehicle_renewal",
              value.theObject.id
          );
          break;
        case "notification":
          console.log("notification");
          break;
        case "history":
          console.log("history");
          break;
      }
    },
    messageModuleFilter: function(value,search,item){
      let isFound = false;
      let patt = new RegExp(search, "i")

      // Search the Object
      isFound = this.searchObject(item,patt,isFound)

      return isFound;
    }
  },
  mounted() {
    console.log(this.group)
    this.$store
        .dispatch(this.isAdministrator?"messages/loadAllMessages":"messages/loadAllContactMessages")
        .then(() => {
          this.isLoading = false;

        });
  }
};
</script>
<style scoped lang="stylus">
.editButtons {
  display: flex;
}

.dottedUnderline {
  border-bottom: 1px dotted;
  text-decoration: none;
}
  .contactRound{
    padding 1px 2px;
    margin 2px 2px;
    border-radius 3px;
    font-size 11px;
  }
</style>

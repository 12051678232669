<template lang="pug">
div(v-if="$store.state.reminders.contact_renewal.currentIndex > -1")
  v-toolbar(dense, flat)
    v-toolbar-title Contact Renewal Form
    v-spacer
  
  

  
  v-container.py-2
    v-alert(v-if="error" :value="true" color="error" icon="warning") 
      div(v-for="e,index in errors" :key="index" v-text="e")

    v-layout(justify-end)
      v-btn.ma-2(small text color="grey" @click="cancel") cancel
      v-btn.ma-2(small outlined color="primary" @click="saveAdd"  ) Save &amp; Add another
      v-btn.ma-2(small color="primary" @click="save"  ) Save

  v-container
    v-card
      v-form(ref="form" v-model="valid" lazy-validation)
       v-container.grid-list-xl()
        v-row(align="end")
          v-col(cols="12" xs="12", md="6")
            contacts-auto-complete(v-model="user_contacts_id" :multiple="true")
          v-col(cols="12" xs="12", md="6")
            autos-combo-box(v-model='contact_renewal_types_id'  label="Select Contact Renewal Types" module="reminders" submodule="contact_renewal_type"   actionPrefix="Contact_renewal_type")
        v-divider
        v-row()
          v-spacer()
          v-col(cols="12" xs="12", md="8" )
            time-reminder(v-model="timeReminderObj")
          v-spacer()
        v-divider
        v-row()
          v-col(xs="12", md="6")
            contacts-auto-complete(v-model="contacts_id")
          v-col(xs="12", md="6")
            v-row()
              v-col(cols="6")
                v-switch(label="Email Notifications" v-model="email_notifications" hint="If ON, emails are sent when reminder is 'due soon' or overdue", :persistent-hint="true")
              v-col(cols="6")
                v-switch(label="SMS Notifications" v-model="sms_notifications" hint="If ON, sms are sent when reminder is 'due soon' or overdue", :persistent-hint="true")
        v-row
          v-col(cols="12")
            v-textarea(v-model="comments" rows=3 label="comments")

  v-container.py-2
    v-alert(v-if="error" :value="true" color="error" icon="warning") 
      div(v-for="e,index in errors" :key="index" v-text="e")

    v-layout(justify-end)
      v-btn.ma-2(small text color="grey" @click="cancel") cancel
      v-btn.ma-2(small outlined color="primary" @click="saveAdd"  ) Save &amp; Add another
      v-btn.ma-2(small color="primary" @click="save"  ) Save
</template>
<script>
import ContactsAutoComplete from "@/modules/global/components/forms/ContactsAutoComplete";
import { createHelpers } from "vuex-map-fields";
import TimeReminder from "@/modules/reminders/components/ui/TimeReminder";
import AutosComboBox from "@/modules/global/components/forms/AutosComboBox";
const { mapFields } = createHelpers({
  getterType: "reminders/contact_renewal/getContact_renewal",
  mutationType: "reminders/contact_renewal/updateContact_renewal"
});

export default {
  components: {
    ContactsAutoComplete,
    TimeReminder,
    AutosComboBox
  },
  data: () => ({
    error: false,
    errors: [],
    valid: false,
    switch1: true,
    switch2: true
  }),

  computed: {
    ...mapFields([
      "user_contacts_id",
      "contact_renewal_types_id",
      "comments",
      "start_date",
      "interval",
      "due_date_amount",
      "repeat",

      "contacts_id",
      "email_notifications",
      "sms_notifications"
    ]),

    timeReminderObj: {
      get: function() {
        return {
          start_date: this.start_date,
          interval: this.interval,
          due_date_amount: this.due_date_amount
        };
      },
      set: function(value) {
        console.log("setting the timeReminder object");
        console.log(value);
        this.start_date = value.start_date;
        this.interval = value.interval;
        this.due_date_amount = value.due_date_amount;
      }
    }
  },

  methods: {
    validateForm: function() {
      this.error = false;
      this.errors = [];

      if (this.usersID === null) {
        this.errors.push("Select a Vehicle(s)");
        this.error = true;
      }

      if (
        this.contactRenewalTypeID === null ||
        this.contactRenewalTypeID === "00000000-0000-0000-0000-000000000000"
      ) {
        this.errors.push("Select a Service Type");
        this.error = true;
      }

      if (this.contactID === null) {
        this.errors.push("Select a Contact(s) to send the reminder");
        this.error = true;
      }

      if (this.startDate.getTime() < new Date().getTime()) {
        this.errors.push("Enter a Date and Time in the future.");
        this.error = true;
      }

      return this.error;
    },

    cancel: function() {
      this.$router.push({ name: "reminders", params: { tabID: 2 } }).catch(()=>{});
    },

    save: function() {

            this.$store
                .dispatch("reminders/contact_renewal/saveContact_renewal")
                .then(() => {
                    this.$router.push({name: "reminders", params: {tabID: 2}}).catch(()=>{});
                });

    },

    saveAdd: function() {
      // todo: Save and Add Another
    }
  },

  mounted() {
    // get the Service Type
    if (this.$store.state.reminders.contact_renewal_type.currentIndex === -1) {
      this.$store.dispatch(
        "reminders/contact_renewal_type/loadAllContact_renewal_types"
      );
    }

    if (this.$store.state.reminders.contact_renewal.currentIndex === -1) {
      this.$store
        .dispatch(
          "reminders/contact_renewal/fetchContact_renewal",
          this.$route.params.reminderID
        )
        .then(() => {});
    }
  }
};
</script>
<style scoped lang="stylus"></style>

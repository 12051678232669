<template lang="pug">
  .d-flex.align-center()
    v-text-field(v-model='meter' type="number" :hint="`vehicle ${vehicle_id} at ${time}`", :persistent-hint='true', label='Odometer')
    v-btn.ml-3(fab outlined small color="grey darken-2" @click="getOdometerReadingInMeters" )
      v-icon(color="primary" ) av_timer
</template>
<script>
/**
 * This Component should check if the odometer values are valid based on the vehicle. It should give the approriate error
 */
import Repository from "@/repository";
import moment from "moment";

export default {
  props: ["value", "vehicle_id","time"],
  data: () => ({}),
  methods:{
    getOdometerReadingInMeters: function() {
      Repository.get(`/odometer/getOdometerByTime?vehicle_id=${this.vehicle_id}&time_at=${this.getTime.toISOString()}`).then((response) => {
          this.meter = response.data;
      }).catch( e => {
        console.log(e)
      })
    },
  },

  watch:{
    vehicle_id: function(val, oldVal){
      if (val != oldVal){
        this.getOdometerReadingInMeters();
      }
    },

    time: function(val, oldVal){
      if (val != oldVal){
        this.getOdometerReadingInMeters();
      }
    }
  },

  computed: {



    getTime: {
      get: function() {
        if (this.time) {
          return moment(this.time);
        }else{
          return moment();
        }
      }
    },

    meter: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
<style scoped lang="stylus"></style>

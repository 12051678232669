<template lang="pug">
  div()
    //-- div() {{element}}
    //--.title() {{result}}
    template(v-if="element.type == 'integer' || element.type == 'decimal'")
      v-text-field(v-model.number="result" :hint="element.label" :persistent-hint="true"  :label="element.label"  clearable )
    template(v-else-if="element.type == 'boolean'")
      v-switch(v-model="result" :label="element.label" :persistent-hint="true" )
    template(v-else)
      v-text-field(v-model="result" :hint="element.label" :persistent-hint="true"  :label="element.label"  clearable )
</template>

<script>
export default {
  props: [ "element", "mutationTypePath" , "getterCurrentItemPath"],
  data: () => ({
  }),
  computed:{
    result:{
      get: function() {

        if (this.$store.getters[this.getterCurrentItemPath]["details"] == null){
          // set default if it exists
          if (this.element.details != null && this.element.details.default != undefined  ) {
            return this.element.details.default;
          }else{
            return null;
          }
        }else{
          if (this.element.details != null && this.element.details.default != undefined  ) {
            return this.element.details.default;
          }else{
            return this.$store.getters[this.getterCurrentItemPath]["details"][`${this.element.label_id}`];
          }
        }

      },
      set: function(newVal) {
        this.$store.commit(this.mutationTypePath, {path: `details.${this.element.label_id}`, value: newVal})
      }
    }
  },


  methods:{
      mutateAsDefaultValue: function(newVal) {
        this.$store.commit(this.mutationTypePath, {path: `details.${this.element.label_id}`, value: newVal})
      }
  },

  mounted() {


    // initialize if details does not exist
    if(this.$store.getters[this.getterCurrentItemPath].details == undefined){
      let obj = {}
      obj[this.element.label_id] = null
      this.$store.commit(this.mutationTypePath, {path: `details`, value: obj})
      this.result = null;
    }

    this.$nextTick(()=>{
      if (this.element.details != null && this.element.details.default != undefined  ) {
        this.mutateAsDefaultValue(this.element.details.default);
      }
    });

  }
}
</script>

<style scoped>

</style>
<template lang="pug">
  div
    v-autocomplete(
      v-model="vehicles"
      :items="$store.state.vehicles.all" 
      :loading="isLoading" 
      :search-input.sync="search" 
      item-text="name" 
      item-value="id" 
      :label="label"
      placeholder="Start typing to Search"
      multiple
      chips
      @change="onChange"
      :filter="onFilter"
      :disabled="disabled"
      ) 
      template(slot="selection" slot-scope="data")
        v-chip.chip--select-multi(:input-value="data.selected" close @click:close="onRemove(data.item.id)")
          image-thumbnail.ml-n2.mr-2(:width='30', :height='30', :radius='15' :src="data.item.thumbnail_id")
          | {{data.item.name}}
      
      template(slot="item" slot-scope="data")
        template(v-if="typeof data.item !== 'object'")
          v-list-item-content(v-text="data.item")
        template(v-else)
          v-list-item-content
            v-layout(align-center)
              image-thumbnail(:width='60', :height='40', :src="data.item.thumbnail_id")
              v-layout.ml-2(column)
                .body-2.font-weight-bold {{data.item.name}}
                .caption {{data.item.make}} · {{data.item.model}} · {{ data.item.numberplate}}
</template>
<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";

export default {
  components: {
    ImageThumbnail
  },
  props: {
    value:{
      type:Array,
    },
    multiple:{
      type:Boolean,
      default: false,
    },
    label:{
      type:String,
      default: "Select Vehicles"
    },
    disabled:{
      type: Boolean,
      default: false
    }

  },
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    vehicles: null,
    search: null,
  }),

  methods: {
    setData: function(theData) {
      this.$emit("input", theData);
    },

    onChange: function(theValue) {
      this.$emit("input", theValue);
    },

    onRemove: function(item) {
      let tempVehicles = this._.cloneDeep(this.vehicles);
      const theIndex = tempVehicles.findIndex(value => value === item);
      tempVehicles.splice(theIndex, 1);
      this.vehicles = tempVehicles;
      this.$emit('input',this.contacts)
    },

    onFilter: function(item, queryText) {
      const hasValue = val => (val != null ? val : "");
      const query = hasValue(queryText);
      const toSearchText =
        item.name +
        " " +
        item.vin +
        " " +
        item.model +
        " " +
        item.make +
        " " +
        item.numberplate;
      return (
        toSearchText
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    }
  },

  mounted() {
    console.log(this.multiple);
    this.vehicles = this.value;
    if (this.$store.state.vehicles.currentIndex === -1) {
      this.isLoading = true;
      this.$store.dispatch("vehicles/loadAllItems").then(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>
<style scoped lang="stylus">
.imageCrop
  width: 60px
  height: 40px
  color red
  border-radius 5px
  background-color #CCCCCC
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
</style>

<template lang="pug">
  div(v-if="groupsLoaded && contactsLoaded && customersLoaded && merchantsLoaded    ")
    v-progress-linear(v-if="isLoadingTable" indeterminate)
    .placeTable(v-if="this.$store.state.place.currentIndex > -1")
      v-data-table.elevation-1(
        v-model="selectedPlaces"
        :search="search"
        :custom-filter="placeModuleFilter"
        :headers='headers',
        :items='getData'
        item-key="id"
        :items-per-page="footerProps.pagnation.itemsPerPage",
        :footer-props="footerProps"
        show-select
      )
        template(v-slot:progress)
          v-progress-linear(slot='progress', height='2', :indeterminate='loading')

        template(v-slot:item.name="{item}")
          .placeName()
            template(v-if="item.is_hub")
              .font-weight-bold(@click="onClickRow(item)" :class="item.is_geofence?'accent--text':'primary--text'") {{ item.name}}
            template(v-else)
              .d-flex.flex-row()
                v-icon.mr-4(@click="onShowMap(item)" ) place
                div(@click="onClickRow(item)")
                  .font-weight-bold(:class="item.is_geofence?'accent--text':'black--text'") {{ item.name}}
                  .caption.grey--text() {{item.description}}

        template(v-slot:item.group_id="{item}")
          div(v-if="groupsLoaded && item.group_id != null && getGroup(item.group_id)")
            v-chip.font-weight-bold(small :color="getGroup(item.group_id).color" ) {{ getGroup(item.group_id).name}}

        template(v-slot:item.street="{item}")
          .caption() {{ item.street }}

        template(v-slot:item.radius="{item}")
          div(style="width:30px;text-align:center") {{ item.radius }}


        template(v-slot:item.author_id="{item}")
          div(v-if="contactsLoaded && item.author_id != null && getContact(item.author_id)")
            //- div() {{getContact(item.author_id).first_name}}
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-avatar(color="grey lighten-2" :size="30" v-on="on") {{getContact(item.author_id).first_name[0]}} {{getContact(item.author_id).last_name[0]}}
              span() {{getContact(item.author_id).first_name}} {{getContact(item.author_id).last_name}}


        template(v-slot:item.customers_id="{item}")
          div(v-if="item.customers_id != null && customersLoaded")
            div(v-for="(id,index) in item.customers_id" :key="index")
              .caption(v-if="getCustomer(id)")
                .span(v-if="getCustomer(id).reference_id") {{getCustomer(id).reference_id }}
                .span() {{getCustomer(id).name }}

        template(v-slot:item.merchants_id="{item}")
          div(v-if="item.merchants_id != null && merchantsLoaded")
            div(v-for="(id,index) in item.merchants_id" :key="index")
              .caption(v-if="getMerchant(id)" )
                //-- .span(v-if="$store.getters['merchant/getCustomerByID'](id).reference_id") {{$store.getters["merchant/getCustomerByID"](id).reference_id }}
                .span() {{getMerchant(id).name }}





        template( v-slot:item.action="{ item }")
          v-row.mr-7(justify="end")
            more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')

      map-dialog.mapDialog(v-show="coordinates"  @closeMap="onCloseMap" :coordinates="coordinates" :radius="radius" :label="mapLabel" :zoom="zoom")
</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import MapDialog from "@/modules/global/components/table/MapDialog";
import {searchMixin} from "@/mixin/searchListMixin";
// @mouseout="onCloseMap()" @wheel="onWheel"
export default {
  props: {
    search: String,
    selectedItems: Array,
    group: String,
  },


  components: {
    MoreDropDownMenu,
    ImageThumbnail,
    MapDialog
  },
  mixins: [searchMixin],
  data: () => ({
    groupsLoaded: false,
    contactsLoaded: false,
    customersLoaded: false,
    merchantsLoaded: false,
    footerProps: {
      "items-per-page-options": [5, 10, 20, 50, 100, 1000],
      pagnation: {itemsPerPage: 20}
    },
    isLoadingTable: false,
    coordinates: null,
    radius: null,
    mapLabel: null,
    zoom: 0,
    loading: false,
    pagination: [5, 10, 20, 50, 100],
    rowsPerPage: 20,
    headers: [

      {text: "Name", value: "name"},
      {text: "Street", value: "street"},
      {text: "Group", value: "group_id"},
      {text: "Customer", value: "customers_id"},
      {text: "Merchant", value: "merchants_id"},
      {text: "Author", value: "author_id"},
      // {text: "Contact", value: "contact_id"},


      {text: "Action", value: "action", align: "right"}
    ],

    menuitems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Duplicate", icon: "content_copy", action: "duplicate"},
      {title: "Delete", icon: "delete", action: "delete"}
    ]
  }),

  computed: {
    getData: function () {
      return this.$store.getters["place/getFilterByGroup"](this.group)
    },

    selectedPlaces: {
      get: function () {
        return this.$store.getters["routing/getSelectedPlace"];
      },
      set: function (value) {
        this.$store.commit("routing/setSelectedPlaces", value);
      }
    },


  },

  methods: {
    getGroup(groupID) {
      if (groupID == null){
        return null;
      }
      var result = this.$store.getters["settings/groupSettings/getGroupByID"](groupID)
      if (result == null) {
        return null
      }else{
        return result;
      }
    },

    getCustomer(customerID) {
      var result = this.$store.getters["customer/getCustomerByID"](customerID)
      if (result == null || result == undefined) {
        return null
      }else{
        return result;
      }
    },

    getMerchant(merchantID) {
      var result = this.$store.getters["merchant/getCustomerByID"](merchantID)
      if (result == null || result == undefined) {
        return null
      }else{
        return result;
      }
    },

    getContact(contactID) {
      let contact =  this.$store.getters["contacts/getContactByID"](contactID)
      if (contact == undefined){
        return null
      }
      return contact;
    },

    onWheel(event) {
      if (event.deltaY < 0) {
        this.zoom += 1;
      } else if (event.deltaY > 0) {
        this.zoom -= 1;
      }
    },


    onShowMap(item) {
      this.coordinates = item.location;
      this.mapLabel = item.name;
      this.radius = item.radius / 1000;
    },
    onCloseMap() {
      this.coordinates = null;
    },

    onClickRow: function (item) {
      let value = {};
      value.theObject = item;
      value.action = "edit"
      this.onClick(value);
    },
    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("place/setCurrentIndexByID", value.theObject.id);
          this.$store.commit("place/setRefresh",true);
          this.$router.push({
            name: "placeDetailsForm",
            params: {placeID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          this.authorsLoaded = true;
          confirm("Are you sure you want to delete this item?") &&
          this.$store.dispatch("place/deletePlace", value.theObject.id);
          break;
        case "duplicate":
          this.$store.commit("place/setCurrentIndexByID", value.theObject.id)
          this.$store.dispatch("place/duplicatePlace", value.theObject.id);
          break;
      }
    },

    placeModuleFilter: function (value, search, item) {
      let isFound = false;
      let patt = new RegExp(search, "i");
      // Search the Object
      isFound = this.searchObject(item, patt, isFound);

      // Search Group
      isFound = this.searchObject(item.group, patt, isFound);

      // Search Contact
      isFound = this.searchList(item.contact, patt, isFound);

      return isFound;
    },
  },

  mounted() {
    if (this.$store.state.settings.groupSettings.group_data.length < 2) {
      this.$store.dispatch("settings/groupSettings/loadAllGroups").then(() => {
        this.groupsLoaded = true;
      });

    } else {
      this.groupsLoaded = true;
    }

    if (this.$store.state.contacts.contact_data.length < 2) {
      this.$store.dispatch("contacts/loadAllContacts").then(() => {
        this.contactsLoaded = true;
      });
    } else {
      this.contactsLoaded = true;
    }

    if (this.$store.state.customer.customer_data.length < 2) {
      this.$store.dispatch("customer/loadAllCustomers").then(() => {
        this.customersLoaded = true;
      });
    } else {
      this.customersLoaded = true;
    }

    if (this.$store.state.merchant.merchant_data.length < 2) {
      this.$store.dispatch("merchant/loadAllMerchants").then(() => {
        this.merchantsLoaded = true;
      });
    } else {
      this.merchantsLoaded = true;
    }

    if (this.$store.state.place.currentIndex == -1) {
      this.isLoadingTable = true;
      // load Data
      this.$store.dispatch("place/loadAllPlaces").then(() => {
        this.isLoadingTable = false;
      });
    }
  }
};
</script>

<style scoped>
.show_hand:hover {
  cursor: pointer;
}

.mapDialog {
  display: block;
  position: fixed;
  width: 50%;
  z-index: 100;
  top: 20%;
  left: calc(25%);
}

.placeName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  background-color: rgba(256, 256, 255, 0);
  cursor: pointer;
}
</style>

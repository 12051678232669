<template lang="pug">
  table.AlertMeter
    tr
      th.primary.py-0(width="10px")
        v-switch(v-model="switch1" color="white")
      th.primary.py-0.px-0
          div.subheading Odometer Reminder
            .caption.primary.white--text  Leave all fields blank if you don't want to use this reminder by odometer
      th.primary.darken-1(v-show="switch1") 
        .span.subheading Starting at:
        div.white--text.font-weight-bold {{start_odometer}}

    tr
      td(colspan="2")
        v-container(v-show="switch1")
          v-layout.pa-2(column)
            v-flex(xs12)
              v-text-field(v-model="interval_odometer",type="number", label="Meter Interval", hint="Repeat based on usage (e.g. Oil Change every 5,000 miles)" :persistent-hint="true" prepend-icon="autorenew")
            v-flex(xs12)
              .threshold
                v-text-field(v-model="due_odometer", label="Meter Threshold", hint="Number  of miles/km in advance you consider this reminder to be 'due soon' (ex: 500 miles is common for a typical fleet vehicle)", :persistent-hint="true")
            v-flex(xs12)
              v-text-field(v-model="start_odometer", type="number" label="Start Odometer" hide-details prepend-icon="local_shipping")
              .caption.grey--text.text--darken-2.pt-2 Start of Odometer reading. Should be greater than: {{switch1}}
      td.grey.lighten-3.sampleColumn.body-2( v-show="switch1" style="width:200px")              
        div(v-for="i in 10" :key="i") 
          | {{ parseInt(start_odometer)+(parseInt(i)*interval_odometer) }} 
        .mt-3.mb-1.grey--text.text--darken-1 ...repeats Infinitely

</template>
<script>
export default {
  props: {
    value: Object
  },

  data: () => ({
    switch1: true
  }),
  computed: {
    start_odometer: {
      get: function() {
        return this.value.start_odometer;
      },
      set: function(value) {
        this.$emit("input", {
          start_odometer: value,
          interval_odometer: this.interval_odometer,
          due_odometer: this.due_odometer
        });
      }
    },

    interval_odometer: {
      get: function() {
        return this.value.interval_odometer;
      },
      set: function(value) {
        this.$emit("input", {
          start_odometer: this.start_odometer,
          interval_odometer: value,
          due_odometer: this.due_odometer
        });
      }
    },

    due_odometer: {
      get: function() {
        return this.value.due_odometer;
      },
      set: function(value) {
        this.$emit("input", {
          start_odometer: this.start_odometer,
          interval_odometer: this.interval_odometer,
          due_odometer: value
        });
      }
    }
  }
};
</script>
<style scoped lang="stylus">
.AlertMeter
  border-collapse collapse
  width 100%


.AlertMeter td
  border 1px solid #ddd

th
  padding 16px

.theFirstColumnTitle
  display flex



.AlertMeter th
  padding-top 12px
  padding-bottom 12px
  text-align left
  color white

.sampleColumn
  text-align center

.reminderSmallText
  font-size 12px;
  color white

.threshold
  border 1px solid #CCCCCC
  padding 12px
  border-radius 8px
  background-color #f2f2f2
</style>

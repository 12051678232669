<template lang="pug">
  div
    v-divider
    v-container.pa-0(fluid)
      v-layout.pa-4(row, wrap)
        h2.mx-4 Location Places
        v-spacer
        v-btn.ma-2(small outlined color="primary" :disabled="!$store.state.place.refresh" @click="onRefresh")
          v-icon.mr-2(small) sync
          | Refresh
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on", )
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list(small)
              v-list-item-group(color="primary")
                v-list-item(v-for='item in items', :key='item.title' @click='onAction(item.value)')
                  v-list-item-title() {{ item.title }}
        v-btn.ma-2(small, :color="$store.getters['routing/getSelectedPlace'].length > 1?'primary':'grey'", dark, @click='createRoute') Create Route
        v-btn.ma-2(small, color='primary', dark, @click='openEdit') Add New Location
      v-divider
      v-layout.ma-4(row align-center)
        v-row.fill-width()
          .d-flex.mx-4.fill-width()
            .d-flex.align-center.fill-width()
              v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" style="max-width:300px")
              v-icon(v-if="search.length > 0" color="primary" outlined small @click="search=''") close
              v-select.ml-6(:items='$store.state.settings.groupSettings.group_data', item-text='name', item-value='id', @focus='onGroupSelectFocus', prepend-icon='group', v-model='group', label='Group' clearable style="max-width:350px")
              v-spacer()
              v-btn.ml-4(v-if="this.$store.getters['routing/getSelectedPlace'].length" color="primary" outlined @click="clearAllSelection") Clear All {{this.$store.getters['routing/getSelectedPlace'].length}} Selected Places
      v-divider
      .grey.lighten-4.py-3
      v-divider
      places-table( :search="search" :group="group")
      v-divider
    export-data-dialog(:fileExport="fileExport" :isFinished="isDownloadFinished" v-bind:show="showExportDialog" @closeExportDialog="onCloseExportDialog" @download="onDownload" )

</template>

<script>
import PlacesTable from "@/modules/places/components/tables/PlacesTable";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog";
import Repository from "@/repository";

export default {
  components: {
    PlacesTable,
    ExportDataDialog,
  },
  data: () => ({
    isDownloadFinished: false,
    fileLink: null,
    showExportDialog: false,
    fileExport: "",
    headers: [
      {"text": "updated", "value": "updated", "align": "left", "visible": true},
      {"text": "name", "value": "name", "align": "left", "visible": true},
      {"text": "description", "value": "description", "align": "left", "visible": true},
      {"text": "lat", "value": "lat", "align": "left", "visible": true},
      {"text": "lon", "value": "lon", "align": "left", "visible": true},
      {"text": "location", "value": "location", "align": "left", "visible": true},
      {"text": "radius", "value": "radius", "align": "left", "visible": true},
      {"text": "is_hub", "value": "is_hub", "align": "left", "visible": true},
      {"text": "street", "value": "street", "align": "left", "visible": true},
      {"text": "group_name", "value": "group_name", "align": "left", "visible": true},
      {"text": "author_first_name", "value": "author_first_name", "align": "left", "visible": true},
      {"text": "author_last_name", "value": "author_last_name", "align": "left", "visible": true}
    ],


    search: "",
    group: null,
    tags: ["open", "approved", "resolved", "closed"],
    items: [
      {
        title: "Export Selected CSV",
        value: "export_selected_csv"
      },
      {
        title: "Export Excel",
        value: "export_excel"
      },
      {
        title: "Export CSV",
        value: "export_csv"
      },
      {
        title:"Delete Selected",
        value: "delete_selected"
      }
    ],

    vehiclesFilter: "",
    searchFilter: ""
  }),

  methods: {

    openEdit: function () {
      this.$store.dispatch("place/addPlace").then(() => {
        this.$router.push({
          name: "placeDetailsForm",
          params: {placeID: this.$store.getters["place/getCurrentPlace"].id}
        }).catch(()=>{});
      });
    },


    onAction: function (value) {
      switch (value) {
        case "export_selected_csv":
          this.fileExport = "CSV";
          this.downloadFile("/report/CSV/place_selected_list");
          this.showExportDialog = !this.showExportDialog;
          break;
        case "export_csv":
          this.fileExport = "CSV";
          this.downloadFile("/report/CSV/place_list");
          this.showExportDialog = !this.showExportDialog;
          break;
        case "export_excel":
          this.fileExport = "Excel";
          this.downloadFile("/report/Excel/place_list");
          this.showExportDialog = !this.showExportDialog;
          break;
        case "delete_selected":
          var deletedIds = [];
          this.$store.getters["routing/getSelectedPlace"].forEach((element) => {
            deletedIds.push(element.id);
          })
          confirm(`Are you sure you want to delete this ${deletedIds.length} item?`) &&
          this.$store.dispatch("place/deleteSelectedPlaces", deletedIds);
          break;
      }

    },
    downloadFile: function (url) {
      this.isDownloadFinished = false;

      Repository({
        method: "POST",
        url: url,
        data: this.headers.filter((item) => item.visible == true),
        responseType: "blob",
      }).then((response) => {
        var fileName = response.headers["file"];

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
    },

    onDownload: function () {
      // download the file
      this.fileLink.click();
      this.onCloseExportDialog();
    },
    onCloseExportDialog: function () {
      this.showExportDialog = false;
    },


    onGroupSelectFocus: function () {
      // Load The group List
      if (this.$store.state.settings.groupSettings.currentIndex == -1) {
        this.$store.dispatch("settings/groupSettings/loadAllGroups");
      }
    },
    showDialog: function () {
    },

    closeDialog: function (value) {
      console.log(value);
    },

    createRoute: function () {
      if (this.$store.getters['routing/getSelectedPlace'].length > 1) {
        this.$store.dispatch('routing/addRouting').then(() => {

          this.$router.push({
            name: "routeDetailsForm",
            params: {placeID: this.$store.getters["routing/getCurrentRouting"].id}
          }).catch(()=>{});
        })
      }


    },

    clearAllSelection: function () {
      this.$store.commit("routing/setSelectedPlaces", []);
    },

    onRefresh: function (){
      this.$store.dispatch("place/loadAllPlaces").then(()=>{

      })
    }
  },

  mounted() {
    // console.log(this.$vuetify.theme.primary);
    if(this.$store.state.place.place_data.length < 2){
      this.$store.commit("place/setRefresh",true);
    }
    this.toggle;
  }
};
</script>

<style scoped></style>

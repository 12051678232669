<template lang="pug">
v-data-table(
  :headers="headers" 
  :search="search" 
  :items="$store.state.reminders.vehicle_renewal.vehicle_renewal_data"
  item-key="id" 
  :loading="isLoading" 
  :single-select="false" 
  show-select
  )

  template(v-slot:progress)
    v-progress-linear(slot="progress"  height="2" :indeterminate="isLoading")

  template(v-slot:item.vehicles="{item}")
    div(v-for="vehicle,index in item.vehicles" :key="index")
      .font-italic() {{vehicle.name}}

  template(v-slot:item.vehicle_renewal_types="{item}")
    div(v-for="vehicle_renewal_type,index in item.vehicle_renewal_types" :key="index")
      .font-weight-bold()   {{vehicle_renewal_type.name}}
  
  template(v-slot:item.job="{item}")
    .caption.grey--text() ID: {{item.job_id}}
    .primary--text(v-if="item.job") {{item.job.next_run_at | moment("dddd, Do MMM YYYY &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp; hh:mm a")}}
  
  template(v-slot:item.due_odometer="{item}")
    .primary--text() {{item.due_odometer == 0 ? 'N/A' : item.due_odometer }}

  template(v-slot:item.contacts="{item}")
    .d-flex
      .grey.rounded-lg.px-3.my-2.mr-2.white--text.darken-1.caption(v-if="item.contacts.length > 0 && item.contacts"  v-for="contact,index in item.contacts" :key="index") {{contact.first_name}} {{contact.last_name}}
  
  template( v-slot:item.action="{ item }")
    v-row.mr-5(justify="end")
      more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')

  template(v-slot:item.comments="{item}")
    .caption() {{item.comments}}
  //- template(slot="items", slot-scope="props")
  //-   tr
  //-     td.text-xs-left
  //-       v-layout(column)
  //-         vehicle-column(v-for="vehicle in props.item.vehicles" :key="vehicle.id" :vehicle="vehicle")
      
  //-     td.text-xs-left 
  //-       div {{ props.item.vehicleRenewalType.name }}
  //-         .caption.grey--text.text--darken-2 {{ props.item.vehicleRenewalType.description }}
  //-     td.text-xs-left
  //-       .primary--text {{props.item.startDate}}
  //-       .caption.grey--text.text--darken-2 interval of {{props.item.interval}} repeated {{props.item.repeat}} times
      
  //-     td.text-xs-left  
  //-       .primary--text {{props.item.startOdometer}} 
  //-       .caption.grey--text.text--darken-2 interval of {{props.item.intervalOdometer}} Miles
  //-     td.text-xs-left.caption 
  //-       v-layout(column)
  //-         div(v-for="contact in props.item.contacts" :key="contact.id")
  //-           router-link.dottedUnderline.black--text(:to="{ name: 'contactDashboard', params: { contactID: contact.id }}") {{contact.first_name}} {{contact.last_name}}
  //-       .caption.my-2
  //-         v-avatar.caption.white--text.mr-1(color='primary', size='20') 88 
  //-         | Emails Sent
  //-         v-avatar.caption.white--text.ml-3.mr-1(color='primary', size='20') 88 
  //-         | SMS Sent
  //-     td.text-xs-left
  //-       .caption.grey--text.text--darken-2 {{ props.item.comments }}
  //-     td.text-xs-left
  //-       more-drop-down-menu(:items="menuitems" @onClick="onClick" :theObject="props.item")

</template>
<script>
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import { searchMixin } from "@/mixin/searchListMixin";
import Repository from "@/repository";
export default {
  components: {
    VehicleColumn,
    MoreDropDownMenu
  },
  mixins: [searchMixin],
  props: ["search"],
  data: () => ({
    isLoading: true,

    headers: [
      {
        text: "Vehicles",
        value: "vehicles"
      },
      { text: "Vehicle Renewal Type", value: "vehicle_renewal_types" },
      { text: "Next Due (Time)", value: "job" },
      { text: "Next Due (Milage)", value: "due_odometer" },
      { text: "Subscribers", value: "contacts" },
      { text: "Comments", value: "comments", align: "left" },
      { text: "Action", value: "action", align: "right" }
    ],

    menuitems: [
      {
        title: "Send Notification",
        icon: "notifications",
        action: "notification"
      },
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" },
      { title: "View Full History", icon: "history", action: "history" }
    ]
  }),

  methods: {
    onClick: function(value) {
      console.log(value);
      switch (value.action) {
        case "edit":
          this.$store.commit(
            "reminders/vehicle_renewal/setCurrentIndexByID",
            value.theObject.id
          );
          this.$router.push({
            name: "vehicleRenewalForm",
            params: { reminderID: value.theObject.id }
          }).catch(()=>{});

          break;
        case "delete":
          this.$store.dispatch(
            "reminders/vehicle_renewal/deleteVehicle_renewal",
            value.theObject.id
          );
          break;
        case "notification":
          console.log("notification");
          Repository.get(`/vehicleRenewal/alarm/${value.theObject.id}`).then(response => {
            console.log(response);
          });
          break;
        case "history":
          console.log("history");
          break;
      }
    }
  },

  mounted() {
    this.$store
      .dispatch("reminders/vehicle_renewal/loadAllVehicle_renewals")
      .then(() => {
        this.isLoading = false;
      });
  }
};
</script>
<style scoped lang="stylus">
.editButtons {
  display: flex;
}

.dottedUnderline {
  border-bottom: 1px dotted;
  text-decoration: none;
}
</style>

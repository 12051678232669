<template lang="pug">
div
  v-divider
  v-container.mx-4(fluid)
    v-layout.mr-4.my-2(row, wrap)
      h2 Expense History
      v-spacer
      .text-xs-center
        v-menu(offset-y)
          template(v-slot:activator="{ on }")
            v-btn.mx-2(small, outlined, color='primary', dark, v-on="on")
              | Actions
              v-icon(dark) keyboard_arrow_down
          v-list(dense)
            v-list-item-group(v-model="action" color="primary")
              v-list-item(v-for='item in items', :key='item.title')
                v-list-item-title {{ item.title }}
      v-btn.mx-2(small, color='primary')
        v-icon.pr-2(dark) search
        | Search
      v-btn.mb-2.mx-2(small, color='primary', dark, @click='openNew') Add New Expense
  v-divider
  v-layout.mx-4(row, wrap, align-center)
    v-layout(align-center, style='max-width:900px;')
      v-text-field.pl-2(style='width:10px;', v-model='search',append-icon="search" label='Vehicles Search', :persistent-hint='true', required)
      v-btn.ma-2.grey--text(v-if="search.length > 0" text outlined small @click="search=''") clear all
  v-divider
  .grey.lighten-4.py-3
  v-divider
  expense-table(:search="search")

</template>

<script>
import ExpenseTable from "@/modules/expense/components/tables/ExpenseTable";

export default {
  components: {
    ExpenseTable
  },
  data: () => ({
    action: null,
    search: "",
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ]
  }),
  methods: {
    openNew: function() {
      this.$store.dispatch("expenses/addExpense").then(() => {
        let newID = this.$store.state.expenses.expense_data[
          this.$store.state.expenses.currentIndex
        ].id;
        this.$router.push({
          name: "expenseForm",
          params: { vehicleID: newID }
        }).catch(()=>{});
      });
    },

    showDialog: function() {},

    closeDialog: function() {},

    tutorialAction: function() {
      console.log("tutorial Action Clicked");
    }
  },

  mounted() {
    this.$store.dispatch("expenses/loadAllExpenses");
  }
};
</script>

<style scoped></style>

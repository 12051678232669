<template lang="pug">
div
  v-divider
  v-container(fluid)
    v-layout.my-2(row, wrap)
      h2.mx-4 Vehicle Reminder
      v-spacer
      .text-xs-center
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
              | Actions
              v-icon(dark) keyboard_arrow_down
          v-list
            v-list-item(v-for='item in items', :key='item.title')
              v-list-item-title {{ item.title }}
        v-btn.ma-2(small,color="primary",@click="openEdit")
          v-icon.pr-2(dark) alarm
          | Add Vehicle Reminder
        
  v-divider
  v-layout.mx-4.my-2(column)
      v-chip-group(column  active-class="primary--text")
        v-chip(v-for='toggleItem in toggleItems', :key='toggleItem' small) {{ toggleItem }}
  v-layout.ma-4(row align-center)
    v-row()
      v-col(cols="12" xs="12" md="5" xl="3")
        .d-flex.align-center()
          v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" )
          v-btn(v-if="search.length > 0" text outlined small @click="search=''") clear all

  v-divider
  .grey.lighten-4.py-3
  v-divider
  vehicle-renewal-table

  
      
</template>
<script>
import ToggleChips from "@/modules/global/components/lists/ToggleChips";
import VehicleRenewalTable from "@/modules/reminders/components/tables/VehicleRenewalTable";

export default {
  components: {
    ToggleChips,
    VehicleRenewalTable
  },
  data: () => ({
    select1: "All Reminders",
    selectOptions1: [
      "All Reminders",
      "Overdue Only",
      "Overdue + Due Soon",
      "Due Soon Only"
    ],

    search: "",
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ],
    toggleItems: ["Open", "Overdue", "Resolved", "Closed"]
  }),

  methods: {
    openEdit: function() {
      this.$store
        .dispatch("reminders/vehicle_renewal/addVehicle_renewal")
        .then(() => {
          let currentIndex = this.$store.state.reminders.vehicle_renewal
            .currentIndex;
          let currentObject = this.$store.state.reminders.vehicle_renewal
            .vehicle_renewal_data[currentIndex];
          this.$router.push({
            name: "vehicleRenewalForm",
            params: { reminderID: currentObject.id }
          }).catch(()=>{});
        });
    },

    showDialog: function() {},

    closeDialog: function(value) {
      console.log(value);
    }
  }
};
</script>
<style scoped lang="stylus">
.filterStyles{
  max-width 900px;
}
</style>

<template lang="pug">
  table.AlertMeter
    tr
      th.primary.py-0(width="10px")
        v-switch(v-model="switch2" color="white")
      th.primary.py-0.px-0
          div.subheading Time Reminder
            .caption.primary.white--text  Leave all fields blank if you don't want to use this reminder by odometer
      th.primary.darken-1(v-show="switch2") 
        .span.subheading Starting at:
        div.white--text.font-weight-bold.body-2 {{ start_date | moment("DD MMM YYYY &nbsp;-&nbsp; hh:mm a")}}
    tr
      td(colspan="2")
        v-container(v-show="switch2")
          v-row()
            v-col(cols="12")
              v-row()
                v-col(cols="6")
                  v-text-field(v-model="timeInterval", label="Time Interval" hide-details prepend-icon='timer' type="number")
                v-col(cols="6")
                  v-select(v-model="timeUnits"  :items="intervalTypes" item-text="name"  item-value="id"  required  hide-details)
              .caption.grey--text Repeat based on time interval (e.g. Car Wash every 1 month)
            v-col(cols="12")
              .threshold
                v-row()
                  v-col(cols="6")
                    v-text-field(v-model="timeThreshold", label="Time Threshold" type="number" hide-details)
                  v-col(cols="6")
                    v-select(v-model="timeThresholdUnits"  :items="intervalTypes"  item-text="name"  item-value="id"  required  hide-details)
                .caption.grey--text Amount of Weeks/Days/Hours you consider this reminder to be 'due soon' (ex: 2 weeks is common for a typical fleet vehicle maintenance reminder) 
            v-col(cols="12")
              auto-date-time-picker(v-model="start_date", labelDate="Due Date (Optional)", labelTime="Due Time")



      td.grey.lighten-3.sampleColumn( v-show="switch2" style="width:220px")
        div.pr-3.body-2(v-for="i in 10" :key="i" style="text-align:right;") 
          | {{start_date  | moment("add",addDuration(i), "DD MMM YYYY &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp; hh:mm a")}}
        .mt-3.mb-1.grey--text.text--darken-1 ...repeats Infinitely

</template>
<script>
import AutoDateTimePicker from "@/modules/global/components/forms/AutoDateTimePicker";
export default {
  components: {
    AutoDateTimePicker
  },
  props: {
    value: Object
  },
  data: () => ({
    switch2: true,
    intervalTypes: [
      { id: "D", name: "Day(s)" },
      { id: "W", name: "Week(s)" },
      { id: "M", name: "Month(s)" },
      { id: "Y", name: "Year(s)" }
    ]
  }),
  computed: {
    start_date: {
      get: function() {
        return this.value.start_date;
      },
      set: function(value) {
        this.$emit("input", {
          start_date: value,
          interval: this.interval,
          due_date_amount: this.due_date_amount
        });
      }
    },

    interval: {
      get: function() {
        return this.value.interval || "P1M";
      },
      set: function(value) {
        this.$emit("input", {
          start_date: this.start_date,
          interval: value,
          due_date_amount: this.due_date_amount
        });
      }
    },

    due_date_amount: {
      get: function() {
        return this.value.due_date_amount || "P2W";
      },
      set: function(value) {
        this.$emit("input", {
          start_date: this.start_date,
          interval: this.interval,
          due_date_amount: value
        });
      }
    },

    // converting the format 'P3W' for the components
    timeInterval: {
      get: function() {
        const hasValue = val => (val != null ? val : "P1M");
        let theInterval = hasValue(this.interval);
        theInterval = this.resolveInterval(theInterval);

        theInterval = theInterval.substring(1, theInterval.length - 1);
        return parseInt(theInterval);
      },

      set: function(value) {
        this.interval = "P" + value + this.timeUnits;
      }
    },

    // for the Time Units converting to 'P1D' into the componetns
    timeUnits: {
      get: function() {
        const hasValue = val => (val != null ? val : "P1M");
        let theUnits = hasValue(this.interval);
        theUnits = theUnits !== "" ? theUnits : "P1M";
        return theUnits[theUnits.length - 1];
      },

      set: function(value) {
        this.interval = "P" + this.timeInterval + value;
      }
    },

    // amount of time considered due soon
    timeThreshold: {
      get: function() {
        const hasValue = val => (val != null ? val : "P2W");
        let theIntervalDue = hasValue(this.due_date_amount);
        theIntervalDue = this.resolveDueDateAmount(theIntervalDue);

        theIntervalDue = theIntervalDue.substring(1, theIntervalDue.length - 1);
        return parseInt(theIntervalDue);
      },

      set: function(value) {
        this.due_date_amount = "P" + value + this.timeThresholdUnits;
      }
    },

    timeThresholdUnits: {
      get: function() {
        const hasValue = val => (val != null ? val : "P2W");
        let theUnitsDue = hasValue(this.due_date_amount);
        theUnitsDue = theUnitsDue !== "" ? theUnitsDue : "P2W";

        return theUnitsDue[theUnitsDue.length - 1];
      },

      set: function(value) {
        this.due_date_amount = "P" + this.timeThreshold + value;
      }
    },

    getReminderItem: function() {
      return null;
    }
  },
  methods: {
    resolveInterval: function(theInterval) {
      if (theInterval == "") {
        theInterval = "P1M";
        this.$emit("onInterval", theInterval);
      }
      return theInterval;
    },
    resolveDueDateAmount: function(theIntervalDue) {
      if (theIntervalDue == "") {
        this.due_date_amount = theIntervalDue = "P2W";
      }

      return theIntervalDue;
    },
    addDuration: function(i) {
      // multiply by the index to get the next step
      let durationUnits = i * this.timeInterval;

      let theDuration = "Days";

      // convert duration characters into plural durations for momment processing
      switch (this.timeUnits) {
        case "D":
          theDuration = "Days";
          break;
        case "W":
          theDuration = "Weeks";
          break;
        case "M":
          theDuration = "Months";
          break;
        case "Y":
          theDuration = "Years";
          break;
      }

      return durationUnits + " " + theDuration;
    },

    allowedDates: function(value) {
      var currentDate = new Date();
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setMilliseconds(0);
      var theDate = new Date(value);
      return theDate >= currentDate;
      //parseInt(val.split('-')[1], 10) % 2 === 0
    },

    setDate: function(date) {
      this.$refs.menu.save(date);
      this.menu = false;

      this.startDate.setFullYear(
        parseInt(date.split("-")[0]),
        parseInt(date.split("-")[1]),
        parseInt(date.split("-")[2])
      );

      // update the state
      this.realStartDate = this.startDate;
    },

    setTime: function(time) {
      this.$refs.menut.save(time);
      this.startDate.setHours(
        parseInt(time.split(":")[0]),
        parseInt(time.split(":")[1])
      );

      // update the state
      this.realStartDate = this.startDate;
    }
  }
};
</script>
<style scoped lang="stylus">
.AlertMeter
  border-collapse collapse
  width 100%


.AlertMeter td
  border 1px solid #ddd

th
  padding 16px

.theFirstColumnTitle
  display flex



.AlertMeter th
  padding-top 12px
  padding-bottom 12px
  text-align left
  color white
</style>
